import {computed, effect, Injectable, signal, WritableSignal} from '@angular/core';
import {Roles} from "../../model";
import {CallReportBuilderService} from "../call-report-builder.service";
import {SpeechSynthesisService} from "../speech-synthesis.service";
import {SpeechRecognitionService} from "../speech-recognition.service";
import {CallReportVoiceInputSteps} from "./call-report-voice-input-steps";
import {CallReportVoiceInputStepsList} from "./call-report-voice-input-steps-list";
import {CallReportVoiceInputStepOptions, CallReportVoiceInputStepWithOptions} from "./model";
import {throttleTime} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class CallReportVoiceInputService {

    steps = CallReportVoiceInputStepsList.steps;
    
    currentStep = signal(this.steps[0]);
    currentStepIndex = signal(0);
    
    onPrev = signal<number>(0);
    onNext = signal<number>(0);
    
    currentStepText = computed(() => {
        const step = this.currentStep();
        const data = this.reportBuilder.inputData();
        if (!data) return '';
        return step.formatText(data, this.currentStepIndex());
    });
    
    currentStepValue = effect(() => {
        const step = this.currentStep();
        const data = this.reportBuilder.inputData()!;
        if (data) {
            const val = step.getValue(data, this.currentStepIndex());
            this.speechRecognitionService.transcript.set(val ?? '');
        }
    }, { allowSignalWrites: true });
    
    currentStepOptions = computed(() => {
        const step = this.currentStep() as CallReportVoiceInputStepWithOptions;
        return step?.options;
    })

    onCurrentStepUpdate = effect(() => {
        console.log('[callReportVoiceInputService] onCurrentStepUpdate');
        const voice = this.reportBuilder.voiceInput();
        if (voice) {
            this.speak();
        } else {
            this.speechSynthesisService.stop();
        }
    },  {allowSignalWrites: true});
    
    onTranscriptUpdate = effect(() => {
        const t = this.speechRecognitionService.transcript()?.toLowerCase();

        const s = this.currentStep();
        const step = s as CallReportVoiceInputStepWithOptions;
        if (step?.options) {
            for (const o of step.options) {
                const selected = !!t && t.startsWith(o.transcript);
                if (selected) {
                    // this.speechRecognitionService.stop();
                    this.select(o);
                } else {
                    o.selected = false;
                }
                
            }
        }
    },  {allowSignalWrites: true});
    
    constructor(private reportBuilder: CallReportBuilderService, 
                private speechSynthesisService: SpeechSynthesisService,
                private speechRecognitionService: SpeechRecognitionService) {
        
        const THROTTLE_DURATION = 500;
        
        this.speechRecognitionService.onPrev
            .pipe(
                throttleTime(THROTTLE_DURATION)
            ).subscribe(() => this._update(this.onPrev));
        this.speechRecognitionService.onNext
            .pipe(
                throttleTime(THROTTLE_DURATION)
            )
            .subscribe(() => this._update(this.onNext));
    }
    
    private _update(s: WritableSignal<number>) {
        console.log('[CallReportVoiceInputService] update', s());
        s.update(x => x + 1);        
    }
    
    setMeetingOverviewStep() {
        console.log('[callReportVoiceInputService] setMeetingOverviewStep');        
        this.currentStep.set(this.steps[0]);
    }
    
    setOutcomeStep(step: CallReportVoiceInputSteps) {
        this.currentStep.set(this.steps[step]);
    }
    
    setOtherThingsStep(i: number) {
        console.log('[callReportVoiceInputService] setOtherThingsStep');
        
        const index = CallReportVoiceInputSteps.Insights + (i - 1);
        this.currentStep.set(this.steps[index]);
    }
    
    next(text: string) {
        console.log('[callReportVoiceInputService] next', text);

        this._stopListening();
        
        const s = this.currentStep();
        if (s.step < CallReportVoiceInputSteps.Notes) {
            const newStep = this._do((text?? '').trim());
            if (s.step == newStep) {
                this.currentStepIndex.update(x => x + 1);
            } else {
                this.currentStepIndex.set(0);
                this._go(newStep);
            }
        }
    }
    
    prev() {
        console.log('[callReportVoiceInputService] prev');
        
        this._stopListening();
        
        const step = this.currentStep().step;
        if (step > 0) {
            if (this.currentStepIndex() > 0) {
                this.currentStepIndex.update(x => x - 1);
            } else {
                this._go(step - 1);
            }
        }
    }

    select(option: CallReportVoiceInputStepOptions) {
        const s = this.currentStep();
        (s as CallReportVoiceInputStepWithOptions)?.select(option);            
    }
    
    async speak() {
        console.log('[callReportVoiceInputService] speak');
        
        const spokeToEnd =await this.speechSynthesisService.speak(this.currentStepText(), Roles.Coach);
        if (spokeToEnd) {
            const r = this.speechRecognitionService;
            if (!r.recording()) { 
                // start screen
                // && (<any>this.currentStep())?.options?.length !== 0) {
                r.start();
            }
        }
    }
    
    private _stopListening() {
        this.speechRecognitionService.stop();
    }
    
    private _go(index: number) {
        console.log('[callReportVoiceInputService] _go: ', index);
        const o = (<any>this.currentStep()).outcome;
        this.currentStep.set(this.steps[index]);
        if (o) {
            (<any> this.currentStep()).outcome = o;
        }
        sessionStorage.setItem('voice-input-step', index + '');
    }

    private _do(text: string): CallReportVoiceInputSteps {
        const data = this.reportBuilder.inputData();
        if (!data) throw Error('[CallReportVoiceInputService] no data');
        
        const s = this.currentStep();
        return s.complete(data, text, this.currentStepIndex());
    }
}
