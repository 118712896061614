<!--<app-top-progress-bar [step]="4" />-->
<!--<app-side-progress-bar [step]="4" [company]="company" [offering]="product" [job]="job" />-->

<!--<app-main-panel class="mobile-layout">-->
<!--    <div class="flex">-->
<!--        <app-report-builder-header [company]="company" [offering]="product" [job]="job"/>-->
<!--    </div>-->
<!--</app-main-panel>-->

<!--<app-main-panel [secondary]="true" [padding]="0" class="sp-form">-->
<div qa="build-rev">
    <h1>Building Report...</h1>
    <h1 class="subtitle">Your report is almost ready for review.</h1>
    
    
    @for (topics of discussionTopics; track topics; let index = $index) {
        <div class="role">
            @if (topics.role) {
                <h1 class="role" [attr.qa]="'roles-' + (index + 1)" qa2="h3-role">Role: {{topics.role}}</h1>
            }
            <div class="group">
                <app-generation-status [count]="topics.marketTrends().length" [loading]="topics.marketTrendsLoading()" [failed]="topics.marketTrendsLoadingFailed()" [name]="'Market Trends'" qa="st-mt"/>
                <app-generation-status [count]="topics.commonProblems().length" [loading]="topics.commonProblemsLoading()" [failed]="topics.commonProblemsLoadingFailed()" [name]="'Common Problems'" qa="st-cp"/>
                <app-generation-status [count]="topics.processSteps().length" [loading]="topics.processStepsLoading()" [failed]="topics.processStepsLoadingFailed()" [name]="'Process Steps'" qa="st-pc"/>
            </div>
        </div>
    }
    
    <div class="generic">
        <app-generation-status [count]="stakeholders" [loading]="stakeholdersLoading" [failed]="stakeholdersLoadingFailed" [name]="'Company Stakeholders'" qa="st-cs"/>
        <app-generation-status [count]="companyNews" [loading]="companyNewsLoading" [failed]="companyNewsLoadingFailed" [name]="'Company News'" qa="st-cn"/>
        <app-generation-status [count]="meetingOpeners" [loading]="meetingOpenersLoading" [failed]="meetingOpenersLoadingFailed" [name]="'Meeting Openers'" qa="st-mo"/>
    </div>

    @if (openingReport) {
        <app-generation-status [loading]="true" [name]="'Opening Report...'" qa="op-r"/>
    }
    
<!--            <button class="primary" (click)="onOpen.emit()" [disabled]="loadingDiscussionTopics || meetingOpenersLoading" qa="save">-->
<!--                Open Report-->
<!--            </button>-->
</div>
<!--</app-main-panel>-->
