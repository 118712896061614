<form #tutorForm="ngForm" (ngSubmit)="submit(tutorForm)"> 
    <textarea appAutosize appAutofocus required rows="1" name="message" id="message" [(ngModel)]="message" (keydown)="keydown($event, tutorForm)" placeholder="Ask the customer..." qa="ask-ai"></textarea>

    <app-voice-input-toggle [listening]="recording()" (click)="toggleRecording()" />
    
    <!--<button type="button" (click)="toggleRecording()" [class.recording]="" title="Click to speak, or simply press the spacebar">        
        <app-recording-animation />
        <span class="material-symbols-outlined mic">mic</span>
    </button>-->
    
    <button type="submit" [disabled]="loading || tutorForm.invalid" qa="sub" title="Send Your Message">
        <span class="material-symbols-outlined">send</span>
    </button>

</form>