export enum ReportSectionType {
    Unknown = 0,    
    MarketTrends = 100,
    CommonProblems = 101,
    ProcessSteps = 102,
    OtherTopics = 103,
    ExploratoryQuestions = 104,
    Stakeholders = 105,
    MeetingOpeners = 106,
    CompanyNews = 201,
    CompanyDescription = 202,
    CompanySize = 203,
    Leadership = 204,
    Products = 205,
    Competitors = 206,
    Strategy = 207,
}