import {computed, Injectable, signal} from '@angular/core';
import {catchError, finalize, of, retry, tap} from "rxjs";
import {HttpClient, HttpDownloadProgressEvent, HttpEventType} from "@angular/common/http";
import {OfferingType, Person, Roles, TutorChatMessage, TutorCoachingLevels} from "../model";
import {AuthService} from "./auth.service";
import {MessageService} from "./message.service";


export class TutorServiceParser {

    parse(text: string): string [][] {
        let lines = text.substring(0, text.lastIndexOf('\n')).split('\n').filter(x => !!x);
        lines = this._cleanupResponseAccountingForAllResponsesInSignleLine(lines);
        return this._cleanupResponseAccountingForNewLinesInMessages(lines);
    }

    private _cleanupResponseAccountingForNewLinesInMessages(lines: string[]) {
        const cleanedUpLines: string[][] = [];

        let message = '';
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];

            if (line.startsWith('ThreadId')) {
                cleanedUpLines.push(line.split(' | '));
            } else {
                message = (message + '\n' + line).trim();
                const args = message.split(' | ');
                if (args.length == 6) {
                    const text = args[1];
                    if (text.endsWith('Metrics:')) {
                        args[1] = text.substring(0, text.length - 10).trim();
                    }

                    cleanedUpLines.push(args);                    
                    message = '';
                }
            }
        }

        return cleanedUpLines;
    }

    private _cleanupResponseAccountingForAllResponsesInSignleLine(lines: string[]) {
        const fixedLines: string[] = [];
        const tokens = ['Customer |', 'Coach |', 'Salesperson |']
        
        for (let line of lines) {            
            while (true) {
                const list = [line.indexOf(tokens[0]), line.indexOf(tokens[1]), line.indexOf(tokens[2])]
                    .filter(x => x > 0);                
                
                if (!list.length) break;
                
                const index = Math.min(...list);
                if (index <= 0) break;
                
                const firstMessage = line.slice(0, index);
                fixedLines.push(firstMessage.trim());

                line = line.slice(index);
            }
            
            fixedLines.push(line);            
        }
        
        return fixedLines;
    }
}

@Injectable({
    providedIn: 'root'
})
export class TutorService {

    threadId = signal<string>('');
    messages = signal<TutorChatMessage[]>([]);
    
    advices = signal<IAdvicesGroup | undefined>(undefined);
    strengths = signal<IStrengthsGroup | undefined>(undefined);
    
    started = computed<boolean>(() => !!this.threadId());
    completed = signal<boolean>(false);
    starting = signal<boolean>(false);
    loading = signal<boolean>(false);
    advicesLoading = signal<boolean>(false);
    loadingFailed = signal<boolean>(false);

    numWhat = signal(0);
    numWhy = signal(0);
    numClarify = signal(0);
    numProblem = signal(0);

    offering = signal('');
    offeringType = signal(OfferingType.Unknown);
    job = signal('');
    customers = signal<Person[]>([]);

    hadRecentChat = signal(false);
    
    static NEXT_STEP_MESSAGE = '**next**'
    private _level = TutorCoachingLevels.Beginner;

    constructor(private http: HttpClient, private authService: AuthService, private messageService: MessageService) {        
        const lastChat = localStorage.getItem('lastChatDate');        
        if (lastChat) {
            const date = new Date(lastChat);
            const expiration = new Date().getTime() - (60 * 24 * 60 * 60 * 1000) // 30 days
            this.hadRecentChat.set(+date > expiration);
        }
    }
    
    start(level: TutorCoachingLevels, offeringName: string, offeringType: OfferingType, job: string | undefined, message: string) {
        this._level = level;
        
        this.offering.set(offeringName);
        this.offeringType.set(offeringType);
        this.job.set(job ?? '');

        this.hadRecentChat.set(true);
        localStorage.setItem('lastChatDate', new Date().toISOString());

        (<any> window).setThreadId = (id: string) => this.threadId.set(id);
        
        let jobFormatted = job == '_' ? '' : job;
        if (jobFormatted && offeringType == OfferingType.MaterialOrIngredientOrComponent) {
            jobFormatted = 'produce ' + jobFormatted;
        }
        
        let subject =  'My Offering: ' + offeringName;
        
        if (this.authService.authenticated()) {
            subject += '. My Name: ' + this.authService.userName()
                + '. My Company: ' + this.authService.userProfile()?.company;
        }
        
        if (jobFormatted) {
            subject += '. Customer job to be done: ' + jobFormatted            
        }
        
        const roles = this.customers().map(x => x.title).filter(x => !!x);
        if (roles.length) {
            subject += '.Customer role: ' + roles.join(',');
        } 
        
        this.completed.set(false);
        this.starting.set(true);        
        this.loading.set(true);        
        this.messages.set([]);
        this.threadId.set('');

        if (message) {
            subject += '\nsalespersonMessage: ' + message;
            this.messages.update(x => [...x, {role: Roles.User, text: message }])
        }
       

        let index = 0;
        return this.http.post('/api/claire/start', {
            level,
            message: subject
        }, {
            observe: "events",
            responseType: <any>"text",
            reportProgress: true
        }).pipe(
            retry(1),
            tap((event) => {
                if ((<any>window).qaLoadingFailure) {
                    if ((<any>window).qaLoadingFailure--) {
                        throw Error('Not implemented');
                    }
                }

                if (event.type == HttpEventType.DownloadProgress) {
                    index = this._readMessages(index, (<HttpDownloadProgressEvent>event).partialText);
                    if (this.messages().length) {
                        this.starting.set(false);
                    }
                } else if (event.type == HttpEventType.Response) {
                    this.starting.set(false);
                    this.loading.set(false);                    
                    
                    this._readMessages(index, (<any>event).body + '\n', true);                    
                }
            }),
            // tap(x => {
            //     console.log('[TutorService] start', x);
            //     this.threadId.set(x.threadId);
            // }),
            // tap((x) => this._processResponse(x)),
            // finalize(() => this.starting.set(false)),
        ).subscribe({
            error: e => {
                console.error('[TutorService] start', e);
                if (e.status == 400 && !!e.error) {
                    this.messageService.warning(e.error);
                }
            }
        });
    }

    getTips(level: TutorCoachingLevels) {
        console.log('[TutorService] getTips', level);
        
        const threadId = this.threadId();
        this.advicesLoading.set(true);
        this.loadingFailed.set(false);

        this.advices.set(undefined);
        this.strengths.set(undefined);
        
        return this.http.put<FollowUpNotes>('/api/claire/tips', {
            threadId, level
        }).pipe(
            retry(3),
            tap(tips => {
                if (tips.advices) {
                    tips.advices.summary = this._replaceKeywords(tips.advices.summary);
                    tips.advices.advices.forEach(x => {
                        x.title = this._replaceKeywords(x.title);
                        x.text = this._replaceKeywords(x.text);
                    });                    
                }
                if (tips.strengths) {
                    tips.strengths.summary = this._replaceKeywords(tips.strengths.summary);
                    tips.strengths.strengths.forEach(x => {
                        x.title = this._replaceKeywords(x.title);
                        x.text = this._replaceKeywords(x.text);
                    });
                }

                this.advices.set(tips.advices);
                this.strengths.set(tips.strengths);                
            }),
            finalize(() => this.advicesLoading.set(false)),
            catchError(e => {
                console.error('Unable to load response', e);
                if (e.status == 400 && !!e.error) {
                    this.messageService.warning(e.error);
                } else {
                    this.messageService.error('Unable to load response');
                    }
                this.loadingFailed.set(true);
                return of([]);
            })            
        );
    }
    
    send(message: string) {
        console.log('[TutorService] send', message);

        const threadId = this.threadId();
        this.loading.set(true);
        this.loadingFailed.set(false);
        
        if (message === TutorService.NEXT_STEP_MESSAGE) {
            message = 'next';
        } else {
            this.messages.update(x => [...x, {role: Roles.User, text: message }])
        }

        let index = 0;
        return this.http.put('/api/claire/say', {
            threadId, message
        }, {
            observe: "events",
            responseType: <any>"text",
            reportProgress: true
        }).pipe(
            retry(1),
            tap((event) => {
                if ((<any>window).qaLoadingFailure) {
                    if ((<any>window).qaLoadingFailure--) {
                        throw Error('Not implemented');
                    }
                }

                if (event.type == HttpEventType.DownloadProgress) {
                    index = this._readMessages(index, (<HttpDownloadProgressEvent>event).partialText);
                } else if (event.type == HttpEventType.Response) {
                    this.loading.set(false);
                    
                    this._readMessages(index, (<any>event).body + '\n', true, message);                    
                }
            }),
            // tap((event) => this._processResponse(event)),
            // finalize(() => this.loading.set(false)),
            catchError(e => {
                if (e.status == 400 && !!e.error) {
                    this.messageService.warning(e.error);
                }
                
                this.loading.set(false);
                this.loadingFailed.set(true);
                return of([]);
            })
        );
    }

    reset() {
        this.threadId.set('');
        this.messages.set([]);
        this.completed.set(false)
        
        this.numWhat.set(0);
        this.numWhy.set(0);
        this.numClarify.set(0);
        this.numProblem.set(0);
    }
    
        
    private _readMessages(index: number, text: string | undefined, fullMessage = false, originalMessage = ''): number {
        if (!text) return index;

        const lines = new TutorServiceParser().parse(text);

        if (fullMessage) {
            console.log('[TutorService] _readMessages', text);
        }
        
        for (let i = index; i < lines.length; i++) {
            const args = lines[i];
            const name = args[0];
            const message = args[1];
            
            if (name == 'ThreadId') {
                this.threadId.set(message);
                continue;
            }

            const role = this._getRole(name);
            const text = this._replaceKeywords(message);

            if (!this.messages().find(x => x.text == text)) {
                this.messages.update(x => [...x, {role, text}]);
            }

            if (args.length == 6) {
                this.numWhat.set(+args[args.length - 4] || 0);
                this.numWhy.set(+args[args.length - 3] || 0);
                this.numClarify.set(+args[args.length - 2] || 0);
                this.numProblem.set(+args[args.length - 1] || 0);
            }
            
            if (message.toLowerCase().indexOf('goodbye') !== -1) {
                this.completed.set(true);
            }
        }
        
        if (fullMessage) {
            if (this.completed()) {
                this.getTips(this._level).subscribe(() => {
                    console.log('[TutorService] asked for tips');
                });
            } else {
                const list = this.messages();
                if (!list.length) { // empty  response, let's resend the same message again
                    this.send(originalMessage);                    
                } else if (this._level == TutorCoachingLevels.Beginner) {                    
                    if ((<any> window).qaTutorAutoMode || (list.length > 0 && list[list.length - 1].role != Roles.Coach)) {                        
                        this.send(TutorService.NEXT_STEP_MESSAGE).subscribe(() => {});
                    }
                }
            }
        }
        
        return lines.length;
    }
    
    private _getRole(name: string): Roles {
        switch (name) {
            case 'Salesperson':
                return Roles.User;
            case 'Customer':
                return Roles.Customer;
            default:                
                return Roles.Coach;            
        }
    }
    private _replaceKeywords(message: string) {
        const replacements: { [key: string]: string } = {
            'EVOC': 'Everyday VOC',
            'OBSERVATION': 'WHAT',
            'IMPLICATION': 'WHY',
            'OUTCOME_STATEMENT': 'CLARIFY',
            'OUTCOME-STATEMENT': 'CLARIFY'
        };

        return Object.keys(replacements).reduce((acc, key) => {
            const regex = new RegExp(key, 'g'); // 'g' flag ensures all occurrences are replaced
            return acc.replace(regex, replacements[key]);
        }, message);
    }
}

export interface IFollowUpItem {
    title: string;
    text: string;
}

export interface IAdvicesGroup {
    summary: string;
    advices: IFollowUpItem[];
}

export interface IStrengthsGroup {
    summary: string;
    strengths: IFollowUpItem[];
}

export interface FollowUpNotes {    
    advices: IAdvicesGroup;
    strengths: IStrengthsGroup;
} 