import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import {Roles, TutorChatMessage, TutorCoachingLevels} from "../../../../model";
import {SpeechSynthesisService} from "../../../../services/speech-synthesis.service";
import {ChatMessageComponent} from "../chat-message/chat-message.component";
import {SpeechRecognitionService} from "../../../../services/speech-recognition.service";

@Component({
    selector: 'app-chat-messages',
    standalone: true,
    imports: [
        ChatMessageComponent
    ],
    templateUrl: './chat-messages.component.html',
    styleUrl: './chat-messages.component.scss',
    // encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessagesComponent implements OnChanges {
    @Input() userInitials = '';
    @Input() loading = false;
    @Input() completed = false;
    @Input() messages: TutorChatMessage[] = [];
    @Input() level = TutorCoachingLevels.Beginner;

    @HostBinding('class.novice') novice = false;
    @HostBinding('class.intermediate') intermediate = false;
    @HostBinding('class.expert') expert = false;
    
    @Output() messageAdded = new EventEmitter(); 
    @Output() onMessageSelected = new EventEmitter<string>(); 
    TutorCoachingLevels = TutorCoachingLevels;
    
    _messages: TutorChatMessage[] = [];

    private _lastReadMessage = 0;
    private _reading = false;
    // speaking = this.speechSynthesisService.speaking;
    
    constructor(private cdref: ChangeDetectorRef, 
                private speechRecognitionService: SpeechRecognitionService,
                private speechSynthesisService: SpeechSynthesisService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.novice = this.level == TutorCoachingLevels.Beginner;
        this.intermediate = this.level == TutorCoachingLevels.Intermediate;
        this.expert = this.level == TutorCoachingLevels.Expert;
        
        if (changes['messages']) {
            this._messages = this.messages;
            this.messageAdded.emit();
            
            if (!this._reading) {
                this._readMessages();
            }
        }
        
        if (changes['loading']) {
            this.messageAdded.emit();
        }
    }

    private _canSpeak() {
        return this.speechSynthesisService.enabled() && !this.speechRecognitionService.recording();
    }
    
    private async _readMessages() {        
        if (!this._canSpeak()) return;

        this._reading = true;
        
        console.log('[ChatMessagesComponent] readMessages', this._lastReadMessage, this.messages);

        while (this._lastReadMessage < this._messages.length) {
            let message = this._messages[this._lastReadMessage];
            
            // this.messageAdded.emit();
            
            if (this.novice || message.role != Roles.User) {
                
                let text = message.text;
                message.speaking = true;
                
                /*this.speechSynthesisService.speak(text, () => {
                    this._addMessage();
                });*/

                const sentences = text
                    .split(/(?<=[.?!])\s+/)
                    .map(sentence => sentence.trim())
                    .filter(sentence => sentence.length > 0);
                
                message.sentences = sentences;
                
                for (let index = 0; index < sentences.length; index++) {
                    const sentence = sentences[index]
                    
                    if (index < sentences.length) {
                        // const _text = sentences.map((s, i) =>
                        //     i == index ? `<span class="active">${s}</span>` : `<span>${s}</span>`).join(" ");

                        message.speakingSentense = index;
                        this._mutate(message, this._lastReadMessage);
                        
                        await this.speechSynthesisService.speak(sentence, message.role);
                    }
                    
                    if (!this._canSpeak()) {
                        break;
                    }
                }

                message.speakingSentense = -1;
                message.speaking = false;
                this._mutate(message, this._lastReadMessage);
            }

            this._lastReadMessage ++;
        }
        this._reading = false;
    }

    
    private _mutate(message: TutorChatMessage, index: number) {
        // message.text = text;
        const list = this._messages.map((x, i) => i == index ? {...message} : x);
        console.log('[ChatMessagesComponent] mutate', this._messages, list);
        this._messages = list;
        this.cdref.detectChanges();
    }
    
}
