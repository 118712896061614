import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges, OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import {CompanyOverviewComponent} from "../company-overview/company-overview.component";
import {DiscussionTopicsListComponent} from "../discussion-topics-list/discussion-topics-list.component";
import {NewsSectionComponent} from "../news-section/news-section.component";
import {SpinnerComponent} from "../../../../components/spinner/spinner.component";
import {
    Company,
    DiscussionTopic, DiscussionTopics, ExploratoryQuestion, MeetingDetails, MeetingOpener,
    NewsArticle, Person,
    ReportSectionRating,
    ReportSectionType,
    SalesPrepReport, Stakeholder
} from "../../../../model";
import {TrendsHeaderComponent} from "../trends-header/trends-header.component";
import {ProblemsHeaderComponent} from "../problems-header/problems-header.component";
import {ProcessStepsHeaderComponent} from "../process-steps-header/process-steps-header.component";
import {ReportHeaderComponent} from "../report-header/report-header.component";
import {
    PureSelectDiscussionTopicsComponent
} from "../../../report-builder/screens/select-discussion-topics/components/pure-select-discussion-topics/pure-select-discussion-topics.component";
import {
    PureSetMeetingDateComponent, SetMeetingDateArgs
} from "../../../report-builder/screens/set-meeting-date/components/pure-set-meeting-date/pure-set-meeting-date.component";
import {TutorPanelComponent, TutorPanelStyle} from "../../../../components/tutor-panel/tutor-panel.component";
import {
    DiscussionTopicsListByRoleComponent
} from "../discussion-topics-list-by-role/discussion-topics-list-by-role.component";
import {IEditableDiscussionTopics} from "../../../../services/report-builder.service";
import {ExploratoryQuestionsComponent} from "../exploratory-questions/exploratory-questions.component";
import {
    PureExploratoryQuestionsComponent
} from "../../../report-builder/screens/exploratory-questions/components/pure-exploratory-questions/pure-exploratory-questions.component";
import {StakeholdersListComponent} from "../stakeholders-list/stakeholders-list.component";
import {CustomerHandoutArgs} from "../../../../services/customer-handouts/customer-handouts-generator";
import {MeetingOpenersComponent} from "../meeting-openers/meeting-openers.component";

export interface SaveMeetingDetailsArgs {
    meetingDetails: MeetingDetails;
    customers: Person[];
}
@Component({
    selector: 'app-pure-report-viewer',
    standalone: true,
    imports: [
        CompanyOverviewComponent,
        DiscussionTopicsListComponent,
        NewsSectionComponent,
        SpinnerComponent,
        TrendsHeaderComponent,
        ProblemsHeaderComponent,
        ProcessStepsHeaderComponent,
        ReportHeaderComponent,
        PureSelectDiscussionTopicsComponent,
        PureSetMeetingDateComponent,
        TutorPanelComponent,
        DiscussionTopicsListByRoleComponent,
        ExploratoryQuestionsComponent,
        PureExploratoryQuestionsComponent,
        StakeholdersListComponent,
        MeetingOpenersComponent
    ],
    templateUrl: './pure-report-viewer.component.html',
    styleUrl: './pure-report-viewer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureReportViewerComponent implements OnInit, OnChanges {
    @Input() editMeetingDate = false;
    @Input() editExploratoryQuestions = false;
    @Input() authenticated = false;
    
    @Input() exporting = false;
    @Input() reportDataLoading = false;
    @Input() reportData: SalesPrepReport | undefined;

    @Input() company: Company | undefined;

    @Input() exploratoryQuestions: ExploratoryQuestion[] = [];
    @Input() meetingOpeners: MeetingOpener[] = [];
    @Input() stakeholders: Stakeholder[] = [];
    @Input() newsArticles: NewsArticle[] = [];
    @Input() discussionTopics: DiscussionTopics[] = [];
    @Input() discussionTopicsEditable: IEditableDiscussionTopics[] | undefined = undefined;
    // @Input() marketTrends: MarketTrend[] = [];
    // @Input() commonProblems: CommonProblem[] = [];
    // @Input() processSteps: ProcessStep[] = [];
    @Input() otherTopics: DiscussionTopic[] = [];
    
    @Input() ratings: ReportSectionRating[] = [];
    @Output() onRatingChange = new EventEmitter<ReportSectionRating>();
    @Output() onScrollToTop = new EventEmitter();
    
    @HostBinding('class.sp-screen') s = true;

    @ViewChild('header', {read: ElementRef}) header!: ElementRef;
    @ViewChild('news', {read: ElementRef}) news!: ElementRef;
    @ViewChild('problems', {read: ElementRef}) problems!: ElementRef;
    @ViewChild('process', {read: ElementRef}) process!: ElementRef;
    @ViewChild('companyOverview', {read: ElementRef}) companyOverview!: ElementRef;

    @Input() activeSection = 'news';
    
    @Output() openAIGuide = new EventEmitter();
    @Output() onSave = new EventEmitter();
    @Output() onSaveMeetingDetails = new EventEmitter<SaveMeetingDetailsArgs>();
    @Output() onSaveExploratoryQuestions = new EventEmitter<ExploratoryQuestion[]>();

    @Output() onExportToPdf = new EventEmitter();
    @Output() onExportCustomerHandouts = new EventEmitter<CustomerHandoutArgs>();
    @Output() onEditDiscussionTopics = new EventEmitter();
    @Output() onEditMeetingDate = new EventEmitter();
    @Output() onEditExploratoryQuestions = new EventEmitter();
    @Output() onCancelEditMode = new EventEmitter();
    
    @Output() onJobDescriptionReport = new EventEmitter<Stakeholder>();
    @Output() onTrendsBriefReport = new EventEmitter<DiscussionTopic>();
    @Output() onProblemsBriefReport = new EventEmitter<DiscussionTopic>();
    @Output() onProcessBriefReport = new EventEmitter<DiscussionTopic>();
    @Output() onRolePlay = new EventEmitter<DiscussionTopic>();
    
    selectedCustomDiscussionTopic: DiscussionTopic | undefined;
    
    ReportSectionType = ReportSectionType;
    TutorPanelStyle = TutorPanelStyle;

    meetingDetails: MeetingDetails | undefined;       
    
    get editMode() {
        return this.discussionTopicsEditable || this.editMeetingDate || this.editExploratoryQuestions;
    }

    marketTrendsRating!: ReportSectionRating;
    commonProblemsRating!: ReportSectionRating;
    processStepsRating!: ReportSectionRating;
    otherTopicsRating!: ReportSectionRating;    
    companyNewsRating!: ReportSectionRating;
    exploratoryQuestionsRating!: ReportSectionRating;
    meetignOpenersRating!: ReportSectionRating;
    stakeholdersRating!: ReportSectionRating;
    
    companyDescriptionRating!: ReportSectionRating;
    companySizeRating!: ReportSectionRating;
    leadershipRating!: ReportSectionRating;
    productsRating!: ReportSectionRating;
    competitorsRating!: ReportSectionRating;
    strategyRating!: ReportSectionRating;

    get exploratoryQuestionsSelected(): ExploratoryQuestion[] {
        return this.exploratoryQuestions.filter(x => x.selected);
    }
    
    get stakeholdersSelected(): Stakeholder[] {
        return this.stakeholders.filter(x => x.selected);
    }

    get newsArticlesSelected(): NewsArticle[] {
        return this.newsArticles.filter(x => x.selected);
    }
     
    
    ngOnInit() {
        this._initRatings();
    }
    
    ngOnChanges(changes: SimpleChanges) {
        if (this.editMode) {
            this.activeSection = 'news';
        } 
        if (this.editMeetingDate) {
            const reportData = this.reportData;
            if (reportData) {
                this.meetingDetails = {
                    relationshipType: reportData.relationshipType ?? '',
                    meetingPurpose: reportData.meetingPurpose ?? '',
                    potentialBenefits: reportData.potentialBenefits ?? [],                    
                    customerCity: reportData.customerCity,
                    customerState: reportData.customerState,
                    meetingType: reportData.meetingType,
                    date: reportData.meetingDate ?? '',
                    time: reportData.meetingTime ?? '',
                }
            }
        }
        if (changes['ratings']) {
            this._initRatings();
        }        
    }
    
    private _initRatings() {
        this.marketTrendsRating = this._getRating(ReportSectionType.MarketTrends);
        this.commonProblemsRating = this._getRating(ReportSectionType.CommonProblems);
        this.processStepsRating = this._getRating(ReportSectionType.ProcessSteps);
        this.otherTopicsRating = this._getRating(ReportSectionType.OtherTopics);
        this.companyNewsRating = this._getRating(ReportSectionType.CompanyNews);
        this.exploratoryQuestionsRating = this._getRating(ReportSectionType.ExploratoryQuestions);
        this.meetignOpenersRating = this._getRating(ReportSectionType.MeetingOpeners);
        this.stakeholdersRating = this._getRating(ReportSectionType.Stakeholders);
        this.companyDescriptionRating = this._getRating(ReportSectionType.CompanyDescription);
        this.companySizeRating = this._getRating(ReportSectionType.CompanySize);
        this.leadershipRating = this._getRating(ReportSectionType.Leadership);
        this.productsRating = this._getRating(ReportSectionType.Products);
        this.competitorsRating = this._getRating(ReportSectionType.Competitors);
        this.strategyRating = this._getRating(ReportSectionType.Strategy);
    }
    
    private _getRating(section: ReportSectionType) {
        const r = this.ratings.find(x => x.section === section);
        return r ?? <ReportSectionRating> {
            section,
            rating: <any> undefined,
            description: ''
        }
    }

    scrollToSection(section: ElementRef) {
        const element: HTMLElement = section?.nativeElement;
        if (!element) return;
        // element.scrollIntoView({behavior: 'smooth', block: 'start'});
        
        window.scroll({
            left: 0,
            top: element.offsetTop - (<HTMLElement>this.header.nativeElement).offsetHeight + 1,
            behavior: 'smooth'
        });
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        if (this.discussionTopicsEditable || this.editMeetingDate) return;
        
        this.setActive('companyOverview', this.companyOverview)
        || this.setActive('process', this.process)
        || this.setActive('problems', this.problems)
        || this.setActive('news', this.news);
    }

    private setActive(id: string, section: ElementRef) {
        if (!section?.nativeElement) return false;
        
        const elementTop = section.nativeElement.offsetTop;
        if ((window.scrollY + (<HTMLElement>this.header.nativeElement).offsetHeight) >= elementTop) {
            this.activeSection = id;
            return true;
        }
        return false;
    }

    saveCustomTopic(topic: DiscussionTopic) {
        const r = this.reportData;
        if (!r) throw Error('Report is not defined');

        const list = r.otherTopics;
        if (list.indexOf(topic) == -1) {
            list.push(topic);
            this.otherTopics = list;
        }
        
        this.selectedCustomDiscussionTopic = undefined;
    }
    
    addDiscussionTopic() {
        this.selectedCustomDiscussionTopic = {
            selected: true,
            title: '',
            description: ''
        };
    }

    rate(rating: ReportSectionRating) {
        console.log('[PureReportViewerComponent] rate', rating);
        this.onRatingChange.emit(rating);        
    }

    exportCustomerHandout(i: number) {
        this.onExportCustomerHandouts.emit({
            trends: i == 1,
            problems: i == 2,
            steps: i == 3,
        });
    }

    saveMeetingDetails() {
        this.onSaveMeetingDetails.emit({
            meetingDetails: this.meetingDetails!,
            customers: this.reportData!.customers
        })
    }
}
