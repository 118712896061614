<a routerLink="/profile/edit/setup" class="back"><i qa="back" class="material-icons">keyboard_backspace</i><span class="text">Back</span></a>

<h1 qa="h1">And finally, let’s set up sales offering(s)</h1>
<div class="legend">
    <b>Your offerings are an integral part of SalesPrep<sup>&reg;</sup> system.</b><br>
    You can add offerings now, or select “My Offerings” in the menu to add them later. 
    
    <br><br>
    When you’re done adding offerings, click <a [routerLink]="['/dashboard']">Dashboard</a> where you can create your 
    first SalesPrep<sup>&reg;</sup> report, practice an interview with Claire, your AI guide, or go through an e-learning module.
</div>