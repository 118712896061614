@if (company) {
    <app-company-logo [company]="company" (click)="showModal = true" />
}
@if (showByDefault || showModal) {    
    <app-call-report-steps-modal
            (onClose)="showModal = false; showByDefault = false;"
            [company]="company"
            [offering]="offering"
            [job]="job"
            [customerLocation]="customerLocation"
            [meetingDate]="meetingDate"            
        />
}
