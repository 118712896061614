<div class="header flex">
    <h1 qa="h11">Create call report</h1>
    @if (compact) {
        <div class="info">
            <span class="title">{{ stepTitle }}</span>
            <app-input-mode-toggle [compact]="true" [voiceInput]="voiceInput" (voiceInputChange)="voiceInputChange.emit($event)" />
        </div>
    }
</div>

<div class="message flex">
    <app-ghostwriter-avatar/>
    
    <div class="text" qa="headtext">
        <h2 qa="h2">Hi, I’m Paige, your AI Ghostwriter.</h2>
        
        I’ll ask you a few questions about your meeting, so please have your notes ready.
        I’ll<br> combine your answers with SalesPrep<sup>&reg;</sup> report insights and draft the following for you:
        
        <ul>
            <li>A <strong>Call Report</strong> to share inside your company</li> 
            <li>A <strong>Customer Recap</strong> you can email to your customer contact(s)</li>
        </ul>
        
        <!--
        <h2>How would you like me to interview you?</h2>
        <app-input-mode-toggle [voiceInput]="voiceInput" (voiceInputChange)="voiceInputChange.emit($event)" />
        -->
    </div>
</div>
