
@if (inline) {
    <h3 class="main">PRICING</h3>
} @else {
    <h1 class="hero">Start your 30-day free trial now!</h1>
}

<div class="content">
    <div class="flex header">
        <h1>{{ inline ? 'Start you 30-Day free trial now' : '&nbsp;'}}</h1>
        <div class="range">
            <a (click)="annualPlans = true" [class.selected]="annualPlans">ANNUAL PLANS</a>
            <a (click)="annualPlans = false" [class.selected]="!annualPlans">MONTHLY PLANS</a>
        </div>
    </div>

    <div class="options">
        <div class="option" qa="opt1">
            <h3>SALESPREP INDIVIDUAL</h3>
            
            @if (annualPlans) {
                <div class="price">$19.95</div>
                <div class="price-note">per month, billed annually</div>
            } @else {
                <div class="price">$24.95</div>
                <div class="price-note">per month</div>
            }
            
            <button class="primary" (click)="onStartTrial.emit()">                
                Start 30-Day Free Trial
                <em>(No credit card required)</em>
            </button>
            
            <div class="note">
                Ideal for the salesperson motivated to engage customers and sell more.
            </div>
            
            <ul class="features">
                <li>Create AI customer insights reports for every customer meeting</li>
                <li>Learn world-class probing techniques through e-learning</li>
                <li>Practice interviews with Claire, your AI Guide</li>
                <li>Downloadable Problem Briefs and Customer Handouts</li>
                <li>Review PDF resources to fine-tune your VOC skills</li>
            </ul>
        </div>

        <!--<div class="option" qa="opt2">
            <h3>SALESPREP + PUBLIC WORKSHOP</h3>
            <div class="price">$495</div>
            <div class="price-note">Includes 1-yr SalesPrep subscription</div>

            <button class="primary" (click)="onSelectWorkshop.emit()">
                Subscribe Now
            </button>

            <div class="note">
                For individuals or small groups. Please <a style="text-decoration: underline" (click)="onContactUs.emit()">contact us</a> with any questions.
            </div>

            <ul class="features">
                <li class="bold">Includes everything in SalesPrep Individual plan, plus...</li>                
                <li>Select a convenient 4-hour public <a target="_blank" href="https://theaiminstitute.com/aim-product-development-training/everyday-voc-workshops/">Everyday VOC workshop</a> date</li>
                <li>Learn What-Why-Clarify probing from the method's inventor</li>
                <li>Role-play probing in small Zoom breakouts with a dedicated coach</li>
                <li>Use hands-on individual breakouts to master SalesPrep software</li>
            </ul>
        </div>-->

        <div class="option" qa="opt3">
            <h3>SALESPREP + PRIVATE WORKSHOP</h3>
            <div class="price">$495<em>/person</em></div>
            <div class="price-note" style="margin: 0 -2px">+$9,000 per group up to 50, plus travel</div>

            <button class="primary contact-us" (click)="onContactUs.emit()">
                Contact Us
            </button>

            <div class="note">
                Ensures larger teams adopt winning preparation &amp; probing habits.
            </div>

            <ul class="features">
                <li class="bold">Includes everything in SalesPrep system + Public Workshop plan, plus</li>
                <li>Same content as 4-hr public <a (click)="onSelectWorkshop.emit()">Everyday VOC workshop</a>... in person or virtually</li>
                <li>3 Monthly follow-up sessions to encourage implementation</li>
                <li>Exec Dashboard to review each team member's progress</li>
                <li>Pre- and post- workshop support for managing change behavior</li>
            </ul>
        </div>
        
    </div>
    
</div>
