import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-speaking-animation',
    standalone: true,
    imports: [],
    templateUrl: './speaking-animation.component.html',
    styleUrl: './speaking-animation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpeakingAnimationComponent {

}
