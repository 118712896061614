<app-main-header class="sp-desktop"/>

<app-mobile-nav-header class="mobile-layout" [title]="stepTitle()" (click)="back()" />

<div class="container">
    <div class="sp-wrapped">
        @if (reportData()) {
            <app-call-report-steps-modal-button
                    ngSkipHydration
                    [company]="reportData()!.company"
                    [offering]="offering()"
                    [job]="reportData()!.job"
                    [customerLocation]="reportData() | customerLocation"
                    [meetingDate]="meetingDate()"
                    [showByDefault]="!currentStep().step && !voiceInput()"
                    />
        }
    </div>
    <router-outlet></router-outlet>
</div>
    
<app-footer-smart/>