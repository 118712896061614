<app-main-header class="sp-desktop"/>

<app-page-header
        class="desktop-layout"
        [title]="'Resources you can download and print'" qa="res-head"
        [text]="'These SalesPrep<sup>&reg;</sup> Resources complement your SalesPrep e-learning and AI Guide. Download them and keep them close at hand.'">
    <svg xmlns="http://www.w3.org/2000/svg" width="50.598" height="62" viewBox="0 0 50.598 62">
        <g id="Resources_Icon" data-name="Resources Icon" transform="translate(-5.125 -2.125)">
            <path id="Path_10" data-name="Path 10" d="M13.32,2.125h22.8a2.494,2.494,0,0,1,1.764.731l17.1,17.1a2.494,2.494,0,0,1,.731,1.764V55.93a8.2,8.2,0,0,1-8.2,8.2H13.32a8.2,8.2,0,0,1-8.2-8.2V10.32A8.2,8.2,0,0,1,13.32,2.125ZM35.092,7.114H13.32a3.211,3.211,0,0,0-3.207,3.207V55.93a3.211,3.211,0,0,0,3.207,3.207H47.527a3.211,3.211,0,0,0,3.207-3.207V22.756Z" transform="translate(0 0)" fill="#fff"/>
            <path id="Path_11" data-name="Path 11" d="M39.723,24.217h-17.1a2.494,2.494,0,0,1-2.494-2.494V4.619a2.494,2.494,0,1,1,4.989,0V19.228H39.723a2.494,2.494,0,1,1,0,4.989Z" transform="translate(13.506 0)" fill="#fff"/>
            <path id="Path_12" data-name="Path 12" d="M36.424,23.614h-22.8a2.494,2.494,0,1,1,0-4.989h22.8a2.494,2.494,0,1,1,0,4.989Z" transform="translate(5.402 14.856)" fill="#fff"/>
            <path id="Path_13" data-name="Path 13" d="M36.424,29.614h-22.8a2.494,2.494,0,1,1,0-4.989h22.8a2.494,2.494,0,1,1,0,4.989Z" transform="translate(5.402 20.259)" fill="#fff"/>
            <path id="Path_14" data-name="Path 14" d="M19.32,17.614h-5.7a2.494,2.494,0,1,1,0-4.989h5.7a2.494,2.494,0,0,1,0,4.989Z" transform="translate(5.402 9.454)" fill="#fff"/>
        </g>
    </svg>
</app-page-header>

<app-page-header
        class="mobile-layout"
        [title]="'Resources'"
        [text]="'These SalesPrep Resources complement your SalesPrep e-learning and AI Guide. Download them and keep them close at hand.'">
    <svg xmlns="http://www.w3.org/2000/svg" width="50.598" height="62" viewBox="0 0 50.598 62">
        <g id="Resources_Icon" data-name="Resources Icon" transform="translate(-5.125 -2.125)">
            <path id="Path_10" data-name="Path 10" d="M13.32,2.125h22.8a2.494,2.494,0,0,1,1.764.731l17.1,17.1a2.494,2.494,0,0,1,.731,1.764V55.93a8.2,8.2,0,0,1-8.2,8.2H13.32a8.2,8.2,0,0,1-8.2-8.2V10.32A8.2,8.2,0,0,1,13.32,2.125ZM35.092,7.114H13.32a3.211,3.211,0,0,0-3.207,3.207V55.93a3.211,3.211,0,0,0,3.207,3.207H47.527a3.211,3.211,0,0,0,3.207-3.207V22.756Z" transform="translate(0 0)" fill="#fff"/>
            <path id="Path_11" data-name="Path 11" d="M39.723,24.217h-17.1a2.494,2.494,0,0,1-2.494-2.494V4.619a2.494,2.494,0,1,1,4.989,0V19.228H39.723a2.494,2.494,0,1,1,0,4.989Z" transform="translate(13.506 0)" fill="#fff"/>
            <path id="Path_12" data-name="Path 12" d="M36.424,23.614h-22.8a2.494,2.494,0,1,1,0-4.989h22.8a2.494,2.494,0,1,1,0,4.989Z" transform="translate(5.402 14.856)" fill="#fff"/>
            <path id="Path_13" data-name="Path 13" d="M36.424,29.614h-22.8a2.494,2.494,0,1,1,0-4.989h22.8a2.494,2.494,0,1,1,0,4.989Z" transform="translate(5.402 20.259)" fill="#fff"/>
            <path id="Path_14" data-name="Path 14" d="M19.32,17.614h-5.7a2.494,2.494,0,1,1,0-4.989h5.7a2.494,2.494,0,0,1,0,4.989Z" transform="translate(5.402 9.454)" fill="#fff"/>
        </g>
    </svg>
</app-page-header>

<app-resources-list class="sp-wrapped sp-padding" [detailed]="true" />

<app-footer-compact/>