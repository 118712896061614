@if (voiceInput()) {
    <div class="header" qa="hd">
        <div class="sp-wrapped">
            <app-call-report-builder-header [compact]="true" [stepTitle]="'1. Meeting overview'" [voiceInput]="voiceInput()" (voiceInputChange)="setInput($event)"/>
        </div>
    </div>

    <div class="sp-wrapped">
        @if (inputData()) {
            <app-voice-input-panel
                    [reviewMode]="isReviewStep()"
                    [listening]="speechRecognitionService.recording()"
                    [input]="speechRecognitionService.transcript()"

                    [text]="callReportVoiceInputService.currentStepText()"
                    [options]="callReportVoiceInputService.currentStepOptions()"

                    [speaking]="speechSynthesisService.speaking()"

                    [goBack]="callReportVoiceInputService.onPrev()"
                    [goNext]="callReportVoiceInputService.onNext()"

                    (onToggleListening)="speechRecognitionService.toggleRecording()"
                    (onSelect)="callReportVoiceInputService.select($event)"
                    (onPrev)="voicePrev()"
                    (onNext)="voiceNext($event)"
            />
            
            @if (isReviewStep()) {
                <app-meeting-overview-form
                        [reviewMode]="true"
                        [data]="inputData()!"
                        (onPrev)="voicePrev()"
                        (onNext)="voiceNext('')"
                />
            }
        }
    </div>
} @else {
            
    <div class="header" qa="hd">
        <div class="sp-wrapped">
            <app-call-report-builder-header [voiceInput]="voiceInput()" (voiceInputChange)="setInput($event)"/>
        </div>
    </div>
    
    <div class="sp-wrapped">
        @if (inputData()) {
            <app-meeting-overview-form 
                    [data]="inputData()!"
                    (onPrev)="prev()"
                    (onNext)="next()"
                    (onValidationFailed)="validationFailed()"
            />
        }
    </div>
}