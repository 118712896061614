import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {SideProgressBarComponent} from "../../../../components/side-progress-bar/side-progress-bar.component";
import {TopProgressBarComponent} from "../../../../components/top-progress-bar/top-progress-bar.component";
import {MainPanelComponent} from "../../../../../../components/main-panel/main-panel.component";
import {FormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {Company, ExploratoryQuestion, Offering} from "../../../../../../model";
import {
    ReportBuilderHeaderComponent
} from "../../../../components/report-builder-header/report-builder-header.component";
import {AutofocusDirective} from "../../../../../../directives/autofocus.directive";

@Component({
    selector: 'app-pure-exploratory-questions',
    standalone: true,
    imports: [
        SideProgressBarComponent,
        TopProgressBarComponent,
        MainPanelComponent,
        FormsModule,
        RouterLink,
        ReportBuilderHeaderComponent,
        AutofocusDirective,
    ],
    templateUrl: './pure-exploratory-questions.component.html',
    styleUrl: './pure-exploratory-questions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureExploratoryQuestionsComponent {
    
    @Input() inlineMode = false;
    @Input() practiceMode = false;
    @Input() company: Company | undefined;
    @Input() product!: Offering;
    @Input() job = '';
    
    @Input() exploratoryQuestionsLoading = false;
    @Input() exploratoryQuestionsLoadingFailed = false;    
    @Input() questions: ExploratoryQuestion[] = [];
    
    @Output() onSkip = new EventEmitter();
    @Output() onAdd = new EventEmitter();
    @Output() onGenerate = new EventEmitter<string[]>();
    @Output() onCreateReport = new EventEmitter<string[]>();
    @Output() onBack = new EventEmitter();
    @Output() onNext = new EventEmitter<ExploratoryQuestion[]>();

    @HostBinding('class.sp-screen') s = true;
    
    selectedIndex = 0;
    options = [
        'Generate 10 Exploratory Questions to help me uncover problems I can investigate with Probing Questions (What-Why-Clarify)',
        'Skip this step. Do not include Exploratory Questions.'
    ];    
    
    potentialBenefitsTips = [
        'Type potential benefit (e.g. reduce defect rates)',
        'Type potential benefit (e.g. increase production line speed)',
        'Type potential benefit (e.g. reduce training time)'
    ];
    
    potentialBenefits = [
        '',
        '',
        ''
    ];

    generate() {
        if (this.selectedIndex == 0) {
            let benefits = this.potentialBenefits.filter(x => !!x);
            console.log('[PureExploratoryQuestionsComponent] onGenerate', benefits);            
            this.onGenerate.emit(benefits);
        } else {
            console.log('[PureExploratoryQuestionsComponent] onSkip');
            this.onSkip.emit();
        }        
    }

    next() {
        console.log('[PureExploratoryQuestionsComponent] onGenerate next', this.questions);
        this.onNext.emit(this.questions);
    }

    save(q: ExploratoryQuestion) {
        console.log('[PureExploratoryQuestionsComponent] save', q);
        q.edit = false;
    }
    
    edit(e: MouseEvent, q: ExploratoryQuestion) {
        e.preventDefault();
        
        console.log('[PureExploratoryQuestionsComponent] edit', q);
        q.edit = true;
    }

    add() {
        this.questions.push({
            edit: true,
            selected: true,
            text: ''
        })
    }

    toggle(q: ExploratoryQuestion) {
        q.selected = !q.selected;    
    }
}
