export enum TutorCoachingLevels {
    Beginner = 1,
    Intermediate = 2,
    Expert = 3    
}

export interface TutorChatMessage {
    role: Roles;
    text: string;

    speaking?: boolean;
    speakingSentense?: number;
    sentences?: string[];
}

export enum Roles {
    Unknown = "unknown",
    Coach = "coach",
    User = "user",
    Customer = "customer"
}