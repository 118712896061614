import {Component} from '@angular/core';
import {
    PureSetMeetingDateComponent,
    SetMeetingDateArgs
} from "./components/pure-set-meeting-date/pure-set-meeting-date.component";
import {ReportBuilderService} from "../../../../services/report-builder.service";
import {Router} from "@angular/router";
import {take} from "rxjs";

@Component({
    selector: 'app-set-meeting-date',
    standalone: true,
    imports: [
        PureSetMeetingDateComponent,
    ],
    templateUrl: './set-meeting-date.component.html',
    styleUrl: './set-meeting-date.component.scss'
})
export class SetMeetingDateComponent {
    saving = false;
    company = this.report.company;
    product = this.report.offering;
    job = this.report.job;
    customers = this.report.customers;
    meetingDetails = this.report.meetingDetails;

    constructor(private report: ReportBuilderService,
                private router: Router) {

        if (!report.job()) {
            const o = report.offering();
            if (o?.jobs?.length) {
                this.router.navigate(['/report-builder', 'job']);
            } else {
                report.job.set('_');
            }
        }
    }

    next(args: SetMeetingDateArgs) {
        console.log('[SetMeetingDateComponent] save', args);

        this.report.exploratoryQuestions.set([]);
        this.report.meetingOpeners.set([]);
        
        if (args.generateReport) {                        
            this.report.generateMeetingOpeners();
            this.router.navigate(['/report-builder', 'generation']);
        } else {
            
            this.report.generateExploratoryQuestions();
            this.router.navigate(['/report-builder', 'topics']);
        }
    }
}
