import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'companySize',
    standalone: true
})
export class CompanySizePipe implements PipeTransform {

    transform(companySize: number | undefined, ...args: unknown[]): unknown {
        if (!companySize) {
            return 0;
        }
        
        companySize = Math.round(companySize * 100) / 100;
        if (companySize / 1000 > 1) {
            return Math.round(companySize / 1000.0) + ' B';
        } else {
            return companySize + ' M';
        }
    }

}
