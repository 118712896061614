
@switch (step) {
    @case (13) {
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="257.343" height="128.859" viewBox="0 0 257.343 128.859">
            <defs>
                <filter id="Union_16" x="0" y="0" width="169.864" height="81.787" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="3" result="blur"/>
                    <feFlood flood-opacity="0.251"/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="What" transform="translate(-114.636 -392.581)">
                <path id="Union_17" data-name="Union 17" d="M41.049,50.072a20,20,0,0,1-20-20V17.365L0,0H109.417a20,20,0,0,1,20,20V30.072a20,20,0,0,1-20,20Z" transform="translate(297.002 521.44) rotate(180)" fill="#ced3d9" opacity="0.6"/>
                <text id="Clarify" transform="translate(183.885 503.638)" font-size="20" font-family="Raleway-BoldItalic, Raleway" font-weight="700" font-style="italic"><tspan x="0" y="0">Clarify</tspan></text>
                <path id="Union_18" data-name="Union 18" d="M28,49.96H0L21,32.633V20A20,20,0,0,1,41,0h54.41a20,20,0,0,1,20,20v9.96a20,20,0,0,1-20,20Z" transform="translate(371.979 482.599) rotate(180)" fill="#ced3d9" opacity="0.6"/>
                <text id="Why" transform="translate(288.451 464.852)" font-size="20" font-family="Raleway-BoldItalic, Raleway" font-weight="700" font-style="italic"><tspan x="0" y="0">Why</tspan></text>
                <g transform="matrix(1, 0, 0, 1, 114.64, 392.58)" filter="url(#Union_16)">
                    <path id="Union_16-2" data-name="Union 16" d="M46.813,63.787a20,20,0,0,1-20-20V22.121L0,0H131.864a20,20,0,0,1,20,20V43.787a20,20,0,0,1-20,20Z" transform="translate(9 6)" fill="#ffb26f"/>
                </g>
                <text id="What-2" data-name="What" transform="translate(175.19 440.635)" font-size="26" font-family="Raleway-BoldItalic, Raleway" font-weight="700" font-style="italic"><tspan x="0" y="0">What</tspan></text>
            </g>
        </svg>

        <div class="text what">
            Can you <strong>describe</strong> this<br> problem? <br><br>
            <strong>When</strong> does it happen?<br><br>
            <strong>Where?</strong><br><br>
            <strong>How often?</strong>
        </div>
    }
    @case (14) {
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="258.06" height="122.859" viewBox="0 0 258.06 122.859">
            <defs>
                <filter id="Union_31" x="81.467" y="11.529" width="176.594" height="86.653" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="3" result="blur"/>
                    <feFlood flood-opacity="0.251"/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Why" transform="translate(-133.351 -398.581)">
                <path id="Union_29" data-name="Union 29" d="M41.049,50.072a20,20,0,0,1-20-20V17.365L0,0H109.417a20,20,0,0,1,20,20V30.072a20,20,0,0,1-20,20Z" transform="translate(284.434 521.44) rotate(180)" fill="#ced3d9" opacity="0.6"/>
                <text id="Clarify" transform="translate(171.317 503.638)" font-size="20" font-family="Raleway-BoldItalic, Raleway" font-weight="700" font-style="italic"><tspan x="0" y="0">Clarify</tspan></text>
                <path id="Union_30" data-name="Union 30" d="M40.823,49.534a20,20,0,0,1-20-20V17.179L0,0H97.933a20,20,0,0,1,20,20v9.535a20,20,0,0,1-20,20Z" transform="translate(133.351 398.581)" fill="#ced3d9" opacity="0.6"/>
                <text id="What" transform="translate(173.386 430.601)" font-size="19" font-family="Raleway-BoldItalic, Raleway" font-weight="700" font-style="italic"><tspan x="0" y="0">What</tspan></text>
                <g transform="matrix(1, 0, 0, 1, 133.35, 398.58)" filter="url(#Union_31)">
                    <path id="Union_31-2" data-name="Union 31" d="M38.479,68.653H0L28.859,44.844V20a20,20,0,0,1,20-20h89.735a20,20,0,0,1,20,20V48.653a20,20,0,0,1-20,20Z" transform="translate(249.06 86.18) rotate(180)" fill="#adffbd"/>
                </g>
                <text id="Why-2" data-name="Why" transform="translate(262 459.557)" font-size="26" font-family="Raleway-BoldItalic, Raleway" font-weight="700" font-style="italic"><tspan x="0" y="0">Why</tspan></text>
            </g>
        </svg>
        
        <div class="text why">
            How does this <strong>impact</strong><br>them?<br><br> 
            <strong>Who</strong> does it impact?
        </div>
    }
    @case (15) {
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="242.672" height="131.824" viewBox="0 0 242.672 131.824">
            <defs>
                <filter id="Union_34" x="0" y="50.037" width="182.865" height="81.787" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="3" result="blur"/>
                    <feFlood flood-opacity="0.251"/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Clarify" transform="translate(-133.556 -401.581)">
                <path id="Union_32" data-name="Union 32" d="M28,49.96H0L21,32.633V20A20,20,0,0,1,41,0h54.41a20,20,0,0,1,20,20v9.96a20,20,0,0,1-20,20Z" transform="translate(376.228 482.599) rotate(180)" fill="#ced3d9" opacity="0.6"/>
                <text id="Why" transform="translate(292.7 464.852)" font-size="20" font-family="Raleway-BoldItalic, Raleway" font-weight="700" font-style="italic"><tspan x="0" y="0">Why</tspan></text>
                <path id="Union_33" data-name="Union 33" d="M40.823,49.534a20,20,0,0,1-20-20V17.179L0,0H97.933a20,20,0,0,1,20,20v9.535a20,20,0,0,1-20,20Z" transform="translate(135.953 401.581)" fill="#ced3d9" opacity="0.6"/>
                <text id="What" transform="translate(175.989 433.601)" font-size="19" font-family="Raleway-BoldItalic, Raleway" font-weight="700" font-style="italic"><tspan x="0" y="0">What</tspan></text>
                <g transform="matrix(1, 0, 0, 1, 133.56, 401.58)" filter="url(#Union_34)">
                    <path id="Union_34-2" data-name="Union 34" d="M46.813,63.787a20,20,0,0,1-20-20V22.121L0,0H144.865a20,20,0,0,1,20,20V43.787a20,20,0,0,1-20,20Z" transform="translate(173.86 119.82) rotate(180)" fill="#5bbffe"/>
                </g>
                <text id="Clarify-2" data-name="Clarify" transform="translate(173.419 498.512)" font-size="26" font-family="Raleway-BoldItalic, Raleway" font-weight="700" font-style="italic"><tspan x="0" y="0">Clarify</tspan></text>
            </g>
        </svg>
        
        <div class="text clarify">
            Begin this with a <strong>verb</strong> like<br> “minimize”<br><br>
            e.g. “<strong>Minimize</strong> time to<br>install equipment”
        </div>
    }
}
