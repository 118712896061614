import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {AutosizeDirective} from "../../../../directives/autosize.directive";
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {CallReportRecodedOutcome} from "../../../../model";
import {
    FormNavigationButtonsComponent
} from "../../../../components/form-navigation-buttons/form-navigation-buttons.component";
import {AutofocusDirective} from "../../../../directives/autofocus.directive";
import {OrdinalPipe} from "../../../../pipes/ordinal.pipe";
import {IndexToLetterPipe} from "../../../../pipes/index-to-letter.pipe";
import {SttInputToggleComponent} from "../../../../components/stt-input-toggle/stt-input-toggle.component";

@Component({
    selector: 'app-outcome-form',
    standalone: true,
    imports: [
        AutosizeDirective,
        ReactiveFormsModule,
        FormsModule,
        FormNavigationButtonsComponent,
        AutofocusDirective,
        OrdinalPipe,
        IndexToLetterPipe,
        SttInputToggleComponent
    ],
    templateUrl: './outcome-form.component.html',
    styleUrl: './outcome-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutcomeFormComponent {

    private _skip: boolean | undefined = undefined;

    @Input() set skip(v: boolean | undefined) {
        if (this._skip == v) {
            return;
        }
        this._skip = v;
        this.skipChange.emit(v);
    };

    get skip() {
        return this._skip;
    }

    @ViewChild('whatInput') whatInput!: ElementRef;
    @ViewChild('whyInput') whyInput!: ElementRef;
    @ViewChild('clarifyInput') clarifyInput!: ElementRef;

    @Input() skipDisabled = false;
    @Input() index = 1;
    @Input() data!: CallReportRecodedOutcome;

    @Output() skipChange = new EventEmitter<boolean | undefined>();
    @Output() onPrev = new EventEmitter();
    @Output() onSkip = new EventEmitter();
    @Output() onNext = new EventEmitter<CallReportRecodedOutcome>();

    @HostBinding('class.sp-form') f = true;
    @HostBinding('class.sp-form-alternative') a = true;

    next(f: NgForm) {
        if (this.skip === true) {
            this.onSkip.emit();
            return;
        }

        if (!this.data.what) {
            this.whatInput.nativeElement.focus();
        } else if (!this.data.why) {
            this.whyInput.nativeElement.focus();
        } else if (!this.data.clarify) {
            this.clarifyInput.nativeElement.focus();
        } else if (f.valid) {
            this.onNext.emit(this.data);
        }
    }
}
