@if (rating.rating === undefined) {

} @else if(!rateAgain) {
    <div class="text">
        Thanks for rating this section! Your feedback is helping to improve the reports. <a class="sp-link" (click)="rateAgain = true">Rate again</a>.
    </div>
} @else {
    <div class="text">Are you happy with what you see in this section?</div>
    
    <div class="actions">
        <a (click)="set(true)">
            <span class="material-symbols-outlined">sentiment_satisfied</span> YES
        </a>
        <a (click)="set(false)">
            <span class="material-symbols-outlined">sentiment_dissatisfied</span> NO
        </a>        
    </div>
}
