import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {IEditableDiscussionTopics} from "../../../../../../services/report-builder.service";
import {GenerationStatusComponent} from "../generation-status/generation-status.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MainPanelComponent} from "../../../../../../components/main-panel/main-panel.component";
import {
    ReportBuilderHeaderComponent
} from "../../../../components/report-builder-header/report-builder-header.component";
import {SideProgressBarComponent} from "../../../../components/side-progress-bar/side-progress-bar.component";
import {TopProgressBarComponent} from "../../../../components/top-progress-bar/top-progress-bar.component";
import {Company, Offering} from "../../../../../../model";
import {SpinnerComponent} from "../../../../../../components/spinner/spinner.component";

@Component({
    selector: 'app-pure-generation',
    standalone: true,
    imports: [
        GenerationStatusComponent,
        FormsModule,
        MainPanelComponent,
        ReactiveFormsModule,
        ReportBuilderHeaderComponent,
        SideProgressBarComponent,
        TopProgressBarComponent,
        SpinnerComponent
    ],
    templateUrl: './pure-generation.component.html',
    styleUrl: './pure-generation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureGenerationComponent {
    @Output() onOpen = new EventEmitter();
    
    @Input() company: Company | undefined;
    @Input() product!: Offering;
    @Input() job = '';
    
    @Input() discussionTopics: IEditableDiscussionTopics[] = [];

    @Input() stakeholders = 0;
    @Input() stakeholdersLoading = false;
    @Input() stakeholdersLoadingFailed = false;
    
    @Input() companyNews = 0;
    @Input() companyNewsLoading = false;
    @Input() companyNewsLoadingFailed = false;

    @Input() meetingOpeners = 0;
    @Input() meetingOpenersLoading = false;
    @Input() meetingOpenersLoadingFailed = false;
    
    @Input() loadingDiscussionTopics = false;
    
    @Input() openingReport = false;
    
    @HostBinding('class.sp-screen') s = true;
}
