import {CallReportPdfGenerator} from "../call-report-pdf-generator";

export class FooterSection {
    constructor() {
    }
    
    draw(pdf: CallReportPdfGenerator) {
        const y = 772.304 - 5;
        const x = pdf.styles.paddingX;

        const user = pdf.userProfile;
        const now = new Date();
        const date = pdf.datePipe.transform(now);
        const time = pdf.timePipe.transform(now.toTimeString());

        const userInfo = !!user ? ` by ${user.firstName} ${user.lastName}` : ``;
        const text = ` Report created ${date} ${time}${userInfo} | `;

        pdf.forEachPage((doc, index, numbersOfPages) => {

            pdf.line(pdf.styles.paddingX, 761.461, 522, "#7abfeb", 1);

            doc.setTextColor(0, 0, 0);
            let w = pdf.text('Customer Meeting SalesPrep', x, y, 'Raleway-Regular', 8);
            w += pdf.text('®', x + w, 770.147 - 3,'Raleway-Regular', 4);
            w += pdf.text(text, x + w, y, 'Raleway-Regular', 8);

            pdf.doc.text(`Page ${index} of ${numbersOfPages}`, pdf.pageWidth - pdf.styles.paddingX + 2, y, {
                align: 'right',
                baseline: 'top'
            })
            
            doc.setTextColor(pdf.styles.linkColor);
            pdf.text("www.salesprep.com", x + w, y);

        });
    }
}