import {Component, effect, signal} from '@angular/core';
import {SalesPrepReport} from "../../../../model";
import {ReportBuilderService} from "../../../../services/report-builder.service";
import {RatingService} from "../../../../services/rating.service";
import {ReportService} from "../../../../services/report.service";
import {Router} from "@angular/router";
import {PureGenerationComponent} from "./components/pure-generation/pure-generation.component";
import {
    PureSetMeetingDateComponent
} from "../set-meeting-date/components/pure-set-meeting-date/pure-set-meeting-date.component";

@Component({
    selector: 'app-generation',
    standalone: true,
    imports: [
        PureGenerationComponent,
        PureSetMeetingDateComponent
    ],
    templateUrl: './generation.component.html',
    styleUrl: './generation.component.scss'
})
export class GenerationComponent {

    company = this.reportBuilderService.company;
    product = this.reportBuilderService.offering;
    job = this.reportBuilderService.job;
    
    discussionTopics = this.reportBuilderService.discussionTopics;
    
    stakeholders = this.reportBuilderService.stakeholders;
    stakeholdersLoading = this.reportBuilderService.stakeholdersLoading;
    stakeholdersLoadingFailed = this.reportBuilderService.stakeholdersLoadingFailed;
    
    companyNews = this.reportBuilderService.newsArticles;
    companyNewsLoading = this.reportBuilderService.companyNewsLoading;
    companyNewsLoadingFailed = this.reportBuilderService.companyNewsLoadingFailed;
    
    meetingOpeners = this.reportBuilderService.meetingOpeners;
    meetingOpenersLoading = this.reportBuilderService.meetingOpenersLoading;
    meetingOpenersLoadingFailed = this.reportBuilderService.meetingOpenersLoadingFailed;
    
    loadingDiscussionTopics = this.reportBuilderService.loadingDiscussionTopics;
    
    saving = false;
    
    openingReport = signal(false);

    onCurrentStepUpdate = effect(() => {
        const s = this.reportBuilderService;
        if (!s.meetingOpenersLoading() && s.meetingOpeners().length > 0) {
            this.openingReport.set(true);
            this.save();
        }
    },  {allowSignalWrites: true});
    
    constructor(private reportBuilderService: ReportBuilderService,
                private ratingService: RatingService,
                private reportsService: ReportService,
                private router: Router) {

        if (!reportBuilderService.job()) {
            const o = reportBuilderService.offering();
            if (o?.jobs?.length) {
                this.router.navigate(['/report-builder', 'job']);
            } else {
                reportBuilderService.job.set('_');
            }
        }        
    }
    
    save() {
        this.saving = true;
        this.reportBuilderService.saveReport().subscribe({
            next: (data: SalesPrepReport) => {
                this.reportsService.reloadList(data);
                this.ratingService.saveBuilderRatings(data.id).subscribe({
                    next: () => console.log('[SetMeetingDateComponent] assigned ratings to reportBuilderService'),
                    error: (e: any) => console.error(e),
                    complete: () => this._openReport(data.id)
                });
            },
            complete: () => this.saving = false
        });
    }

    private _openReport(reportId: string) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        this.router.navigate(['/report', reportId]);
    }
}
