import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    FormNavigationButtonsComponent
} from "../../../../components/form-navigation-buttons/form-navigation-buttons.component";
import {SpeakingAnimationComponent} from "../speaking-animation/speaking-animation.component";
import {CallReportVoiceInputStepOptions} from "../../../../services/call-report-voice-input/model";
import {FormsModule} from "@angular/forms";
import {VoiceInputToggleComponent} from "../../../../components/voice-input-toggle/voice-input-toggle.component";
import {GhostwriterAvatarComponent} from "../../../../components/ghostwriter-avatar/ghostwriter-avatar.component";
import {CallReportVoiceInputSteps} from "../../../../services/call-report-voice-input";
import {StepLegendComponent} from "../step-legend/step-legend.component";

@Component({
    selector: 'app-voice-input-panel',
    standalone: true,
    imports: [
        FormNavigationButtonsComponent,
        SpeakingAnimationComponent,
        FormsModule,
        VoiceInputToggleComponent,
        GhostwriterAvatarComponent,
        StepLegendComponent
    ],
    templateUrl: './voice-input-panel.component.html',
    styleUrl: './voice-input-panel.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoiceInputPanelComponent implements OnChanges {
    @Input() reviewMode = false;
    @Input() listening = false;
    @Input() speaking = false;    
    @Input() text = ''
    @Input() input = ''
    @Input() options: CallReportVoiceInputStepOptions[] | undefined;

    @Input() step = CallReportVoiceInputSteps.Start;
    
    @Input() goBack = 0;
    @Input() goNext = 0;
    
    @Output() onSelect = new EventEmitter<CallReportVoiceInputStepOptions>();
    @Output() onToggleListening = new EventEmitter();
    @Output() onPrev = new EventEmitter();
    @Output() onNext = new EventEmitter<string>();

    @ViewChild('textAreaElement') textAreaElement: ElementRef<HTMLTextAreaElement> | undefined;
    
    content = '';
    formattedText = '';
    nextTitle = '';
    selected : string | undefined;
    _input = '';
    
    speechInputProvided = false;
    
    
    constructor(private cdref: ChangeDetectorRef) {
    }
    
    ngOnChanges(changes: SimpleChanges) {
        console.log('[VoiceInputPanelComponent] ngOnChanges', changes);
        
        if (changes['goBack']?.previousValue !== undefined) {
            this.prev();
        } else if (changes['goNext']?.previousValue !== undefined) {
            this.next();
        }
        
        const inputChange = changes['input'];
        if (inputChange) {
            if (this.listening) {
                this.speechInputProvided = true;
            }
            this._input = this.input;
            setTimeout(() => this.textChange(), 20);            
        }
        
        const listening = changes['listening'];
        if (listening) {
            const p = listening.previousValue;
            const c = listening.currentValue;
            // we don't listen anymore
            if (p && !c) {
                if (this.speechInputProvided) {
                    console.log('[VoiceInputPanelComponent] auto advance');
                    this.speechInputProvided = false;
                    this.next();
                }
            }
        }

        this.formattedText = this.text.replace(/\n/g, '<p>');
        this.updateNextTitle();
    }

    prev() {
        console.log('[VoiceInputPanelComponent] prev ', this._input);
        this._input = '';
        this.onPrev.emit();        
    }
    
    next() {        
        const t = this._input;
        console.log('[VoiceInputPanelComponent] next ', t);
        this.onNext.emit(t);
        this._input = '';
    }

    updateNextTitle() {
        if (this.text == 'Anything else we should include?') {
            this.nextTitle = 'Generate Call Report';
        } else {
            if (!!this.options) {
                this.nextTitle = 'Next';
            } else {
                this.nextTitle = (this._input || '') ? 'Next' : 'Skip';
            }
        }

        this.cdref.detectChanges();
    }

    textChange() {
        this.updateNextTitle();
        
        const e = this.textAreaElement?.nativeElement;
        if (!e) {
            return;
        }

        e.style.overflow = 'hidden';
        e.style.height = 'auto';
        if (e.value) {
            e.style.height = e.scrollHeight + 'px';
        }
    }
}
