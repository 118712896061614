import {Component, OnInit} from '@angular/core';
import {
    CallReportBuilderHeaderComponent
} from "../../components/call-report-builder-header/call-report-builder-header.component";
import {OutcomeFormComponent} from "../../components/outcome-form/outcome-form.component";
import {CallReportRecodedOutcome} from "../../../../model";
import {ActivatedRoute, Router} from "@angular/router";
import {CallReportBuilderService} from "../../../../services/call-report-builder.service";
import {VoiceInputPanelComponent} from "../../components/voice-input-panel/voice-input-panel.component";
import {SpeechSynthesisService} from "../../../../services/speech-synthesis.service";
import {
    CallReportVoiceInputService
} from "../../../../services/call-report-voice-input/call-report-voice-input.service";
import {CallReportVoiceInputSteps} from "../../../../services/call-report-voice-input/call-report-voice-input-steps";
import {SpeechRecognitionService} from "../../../../services/speech-recognition.service";

@Component({
    selector: 'app-outcomes-screen',
    standalone: true,
    imports: [
        CallReportBuilderHeaderComponent,
        OutcomeFormComponent,
        VoiceInputPanelComponent
    ],
    templateUrl: './outcomes-screen.component.html',
    styleUrl: './outcomes-screen.component.scss'
})
export class OutcomesScreenComponent implements OnInit {

    skipDisabled = false;
    skip: boolean | undefined = undefined;
    index = 0;
    inputData = this.reportBuilder.inputData;

    voiceInput = this.reportBuilder.voiceInput;
    currentStep = this.callReportVoiceInputService.currentStep;

    private _outcome: CallReportRecodedOutcome | undefined;
    
    get outcome() {
        if (!this._outcome) {
            this._setOutcome();
        }
        return this._outcome!;
    }
    
    private set outcome(value: CallReportRecodedOutcome) {
        this._outcome = value;
        setTimeout(() => this._setStep(), 100);
    }

    constructor(private reportBuilder: CallReportBuilderService,
                protected callReportVoiceInputService: CallReportVoiceInputService,
                protected speechSynthesisService: SpeechSynthesisService,
                protected speechRecognitionService: SpeechRecognitionService,
                private route: ActivatedRoute,
                private router: Router) {        
    }
    
    ngOnInit() {        
        this.route.params.subscribe(params => {
            const i = (this.route.snapshot.params['index'] || 1) - 1;
            
            if (this.index != i) {
                this.index = i;
                this._setOutcome();                
            }
        });        
    }
    
    private _setOutcome() {
        const d = this.inputData();
        if (!d) return;
        const list = d.outcomes;
        
        if (list.length > this.index) {
            this.outcome = list[this.index];
            this.skip = false;
            this.skipDisabled = true;
        } else {
            this.skipDisabled = false;
            this.skip = undefined;
            this.outcome = {
                what: '',
                why: '',
                clarify: '',
            };
        }
    }

    prev() {
        console.log('[OutcomesScreen] prev', this.index);

        if (this.index > 0) {
            this._updateUrl(this.index - 1);
            this.callReportVoiceInputService.setOutcomeStep(CallReportVoiceInputSteps.OutcomeClarify);
        } else {
            this.router.navigate(['../../overview'], {relativeTo: this.route});
            this.callReportVoiceInputService.setOutcomeStep(CallReportVoiceInputSteps.ReviewMeetingOverviewQuestion);
        }
    }

    next(o: CallReportRecodedOutcome) {
        console.log('[OutcomesScreen] next', this.index);
        const list = this.inputData()!.outcomes;
        let isNewOutcome = list.indexOf(o) === -1;
        
        if (isNewOutcome) {
            if (this.voiceInput() && this.currentStep().step == CallReportVoiceInputSteps.OutcomesQuestion) {
                return;            
            }

            list.push(o);
        }
        
        this._updateUrl(this.index + 1);
    }

    private _setTranscript(text: string) {        
        setTimeout(() => this.speechRecognitionService.transcript.set(text ?? ''), 1);
    }
    
    private _setTranscripts() {
        const s = this.currentStep();
        (<any>s).outcome = this.outcome;
        
        switch (s.step) {
            case CallReportVoiceInputSteps.OutcomeWhat:
                this._setTranscript(this.outcome.what);
                break;
            case CallReportVoiceInputSteps.OutcomeWhy:
                this._setTranscript(this.outcome.why);
                break;
            case CallReportVoiceInputSteps.OutcomeClarify:
                this._setTranscript(this.outcome.clarify);
                break;
        }
    }
    voicePrev() {
        console.log('[OutcomesScreen] voicePrev');
        
        const s = this.currentStep();
        if (s.step > CallReportVoiceInputSteps.OutcomeWhat) {            
            this.callReportVoiceInputService.prev();            
        } else {
            this.prev();            
        }
        this._setTranscripts();
    }
    
    voiceNext(text: string) {
        if (this.currentStep().step == CallReportVoiceInputSteps.OutcomeClarify) {
            this.outcome.clarify = text;
            this.next(this.outcome);
            return;
        }
        
        this.callReportVoiceInputService.next(text);
        this._setTranscripts();
        
        switch (this.currentStep().step) {
            case CallReportVoiceInputSteps.Insights:
                this.skipStep();
                break;

            case CallReportVoiceInputSteps.OutcomesQuestion:
                this.next(this.outcome);
                break;
        }
    }

    skipStep() {
        this.reportBuilder.saveInputData(3);
        this.router.navigate(['../../notes', 1], {relativeTo: this.route});
    }

    private _updateUrl(index: number) {
        console.log('[OutcomesScreen] update url', this.index);
        this.reportBuilder.saveInputData();
        this.router.navigate(['..', index + 1], {relativeTo: this.route});
    }

    private _getStep() {
        if (!this.skipDisabled) {
            return CallReportVoiceInputSteps.OutcomesQuestion;
        }

        return CallReportVoiceInputSteps.OutcomeWhat;
    }
    
    setInput(voiceInput: boolean) {
        if (voiceInput) {
            this._setStep();
        }
        setTimeout(() => this.reportBuilder.setVoiceInput(voiceInput), 500);
    }

    private _setStep() {
        const s = this.callReportVoiceInputService;
        s.currentStep.set(s.steps[this._getStep()]);
        this._setTranscripts();
    }
}
