@if (splashMode) {
    <h3>FAQ</h3>
}
<h1 class="first">Benefits</h1>

<div class="item">
    <a [class.expanded]="visible(1)" (click)="toggle(1)">How do SalesPrep<sup>&reg;</sup> reports boost productivity?</a>
    @if (visible(1)) {
        <div class="answer">
            The typical sales professional can reduce their prep time for customer meetings by 30 minutes per day. At $50/hour, that’s a savings of $500/month... for a subscription cost of just $19.95/month.
        </div>
    }
</div>

<div class="item">
    <a [class.expanded]="visible(2)" (click)="toggle(2)">How does the SalesPrep system increase sales?</a>
    @if (visible(2)) {
        <div class="answer">
            You’ll get vital <em>information</em> about the customer’s world plus uncommon <em>skills</em> to ask great questions. Research shows such questions boost sales because:
            <ul>
                <li>Now you know which of your products to recommend.</li>
                <li>The customer just told you <em>how</em> to sell (which pain points to address).</li>
                <li>Customers love to work with people interested in <em>them</em>.</li>
            </ul>
        </div>
    }
</div>


<h1>Choosing a plan</h1>

<div class="item">
    <a [class.expanded]="visible(3)" (click)="toggle(3)">What’s included in the 30-day Trial Plan?</a>
    @if (visible(3)) {
        <div class="answer">
            You get everything that comes with a paid subscription. You can download customer-specific reports, view the 10 e-learning modules, practice with Claire (your AI Guide) and download PDF resources. You’ll even begin receiving the 12 weekly emails with tips to help you build your probing skills.
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(4)" (click)="toggle(4)">Is the SalesPrep system designed for individuals or companies?</a>
    @if (visible(4)) {
        <div class="answer">
            Both. 
                Those self-motivated enough to watch the e-learning modules—and practice with the AI Guide—will develop amazing probing skills using the individual $19.95/month plan. Want to build these skills in your entire sales team? We have a plan for that too, which can begin with a private workshop called “Everyday VOC<sup>&reg;</sup>.” 
                Visit <a target="_blank" class="sp-link" href="https://www.everydayvoctraining.com">www.everydayvoctraining.com</a> or contact us at 
            <a class="sp-link" href="https://www.talkwithaim.com" target="_blank">www.talkwithaim.com</a>
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(5)" (click)="toggle(5)">What is the “companion” Everyday VOC<sup>&reg;</sup> workshop?</a>
    @if (visible(5)) {
        <div class="answer">
        If you want SalesPrep reports and skills to boost sales across your entire sales team, consider starting with our 4-hour 
            <em>Everyday VOC<sup>&reg;</sup></em> private workshop (virtual or in-person)... 
            which can also be attended by marketing, technical and other functions. With hands-on individual and group practice sessions, this “kickoff” ensures your entire team has the strongest possible start. In addition to all SalesPrep system resources, this includes post-workshop coaching. We’ll even provide you with tracking data so you can recognize and encourage those most enthusiastically embracing new SalesPrep skills. 
                For cost and details, visit <a class="sp-link" href="https://www.everydayvoctraining.com" target="_blank">www.everydayvoctraining.com</a>.
        </div>
    }
</div>


<h1>SalesPrep reports</h1>

<div class="item">
    <a [class.expanded]="visible(6)" (click)="toggle(6)">What’s included in each SalesPrep report?</a>
    @if (visible(6)) {
        <div class="answer">
            Each report has 5 sections, which you can adjust before creating:
            <ul>
                <li><em>Company News</em>: M&A activity, management changes, earnings reports, and other news.</li>
                <li><em>Market Trends</em>: Key trends for the customer application you support with your offering.</li>
                <li><em>Common Problems</em>: Frequent problems with this customer application of your offering.</li>
                <li><em>Process Steps</em>: Major steps for using your offering in this application.</li>
                <li><em>Company Overview</em>: Leadership, size, strategy, products, trade names, competitors.</li>
            </ul>
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(7)" (click)="toggle(7)">How can I view a SalesPrep report?</a>
    @if (visible(7)) {
        <div class="answer">
            You can view these reports on your phone, desktop, or in PDF format.
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(8)" (click)="toggle(8)">Is there a limit to the number SalesPrep reports I can create?</a>
    @if (visible(8)) {
        <div class="answer">
            You can create up to 100 reports per month.
        </div>
    }
</div>


<h1>E-Learning, AI Guide & Resources</h1>

<div class="item">
    <a [class.expanded]="visible(9)" (click)="toggle(9)">Beyond the SalesPrep report, why is e-learning and AI practice included?</a>
    @if (visible(9)) {
        <div class="answer">
            By themselves, SalesPrep reports provide great insights into each customer’s world. But for maximum customer engagement, you need to ask great <em>questions</em>. With our e-learning and Claire (your AI Guide), you’ll master our What-Why-Clarify probing method.
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(10)" (click)="toggle(10)">How long does it take to complete the e-learning?</a>
    @if (visible(10)) {
        <div class="answer">
            There are 10 e-learning modules, and each takes 6-to-12 minutes to complete.
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(11)" (click)="toggle(11)">What are the AI Guide sessions with Claire like?</a>
    @if (visible(11)) {
        <div class="answer">
            For each session, there are three roles: 1) the salesperson, 2) the customer, and 3) Claire (your guide). In the Intermediate and Expert levels, 1) you are the salesperson asking questions, 2) an AI customer explains the problems they have using your type of product, and 3) Claire gives you advice on the best questions to ask the customer. In the Novice level, AI provides all three roles (including the salesperson’s).
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(12)" (click)="toggle(12)">How do I set up a practice session with Claire?</a>
    @if (visible(12)) {
        <div class="answer">
            Once you are logged in, click “AI Guide” at the top. If you have already loaded your offerings and customers’ jobs-to-be-done (their applications), you can select any offering to begin. Or click “+ Add Another Offering” for a new one. You’ll start by asking your AI “customer” if they have any problems.
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(13)" (click)="toggle(13)">Any tips for practicing with Claire?</a>
    @if (visible(13)) {
        <div class="answer">
            Yes, three:
            <ul>
                <li>Try the Novice level first, then spend more time at the Intermediate level, so Claire can give you advice
                    after each of your questions. (At the Expert level, you’ll only get her advice at the end of your
                    interview.)
                </li>
                <li>Don’t be afraid to “push” the AI customer with hard questions, asking for more detail. You’ll be surprised
                    at
                    how smart this customer is!
                </li>
                <li>You want to practice good What-Why-Clarify probing techniques, so watch e-learning module 3 where these are
                    explained. Also watch e-modules 5 and 6, which have advanced probing tips.
                </li>
            </ul>
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(14)" (click)="toggle(14)">What’s the purpose of the PDF resources?</a>
    @if (visible(14)) {
        <div class="answer">
            We recommend you download and print these resources. Keep them near your computer or any place they can serve as helpful reminders... 
                especially the <em>Everyday VOC<sup>&reg;</sup> Guide</em> and the <em>SALT Probing Method</em>.
        </div>
    }
</div>

<h1>Other questions</h1>
<div class="item">
    <a [class.expanded]="visible(15)" (click)="toggle(15)">Do you offer a certification program?</a>
    @if (visible(15)) {
        <div class="answer">
            We will soon. You can begin working toward it now and we’ll keep a record of your progress. To receive a digital SalesPrep Certification badge, you’ll need to:
            <ul>
                <li>Complete all 10 e-learning modules.</li>
                <li>Conduct 10 practice sessions with Claire, your AI Guide (at Intermediate or Expert levels).</li>
                <li>Create 10 SalesPrep customer reports.</li>
            </ul>
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(16)" (click)="toggle(16)">What support will I receive after signing up?</a>
    @if (visible(16)) {
        <div class="answer">
            Once you login—either with a trial or paid subscription—you’ll begin receiving a weekly email for 12 consecutive weeks. These will give you practical tips to master our What-Why-Clarify probing, such as which e-modules to view, and clever ways to practice your new skills.
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(17)" (click)="toggle(17)">Will I learn anything besides how to ask good questions?</a>
    @if (visible(17)) {
        <div class="answer">
            Yes, you’ll also learn...
            <ul>
                <li>How to observe customer operations and “see” what others have overlooked.</li>
                <li>How to improve your listening skills... and how to demonstrate you are keenly interested.</li>
                <li>How to effectively capture customer meeting notes in your CRM system.</li>
                <li>Other related skills, such as conducting effective virtual meetings with customers.</li>
            </ul>
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(18)" (click)="toggle(18)">Is the SalesPrep system designed only for salespeople?</a>
    @if (visible(18)) {
        <div class="answer">
            It’s true this system was designed for B2B sales professionals. But other B2B professionals will gain important benefits when they develop strong probing skills and can prepare with customer-specific reports:
            <ul>
                <li><em>Technical support</em> personnel will analyze customer problems more rapidly and effectively.</li>
                <li><em>Marketing, product management, and R&D</em> will truly understand the customer problems they hope to solve with
                    new
                    products and services.
                </li>
                <li><em>Customer service</em> reps will do a better job of engaging and satisfying customers.</li>
                <li>Your <em>company executives</em> will impress customers with their interest and professionalism.</li>
            </ul>
        </div>
    }
</div>
<div class="item">
    <a [class.expanded]="visible(19)" (click)="toggle(19)">What if my company has security concerns about using AI?</a>
    @if (visible(19)) {
        <div class="answer">
            We share these concerns and have designed SalesPrep software to address them in two ways:
            <ol style="margin-bottom: 0;">
                <li>We have a private agreement with our AI provider(s) under which they agree to NOT use any data you enter for their training purposes.</li>
                <li>You only enter 4 types of data into SalesPrep system, all of which are quite generic:
                    <ul>
                        <li>Your offering type, e.g. “hydraulic cylinders” (with up to 3 generic benefits)</li>
                        <li>Your target company, e.g. “Caterpillar”</li>
                        <li>The target company’s job-to-be-done, e.g. “manufacture backhoes”</li>
                        <li>The target company job function, e.g. “design engineer”</li>
                    </ul>
                </li>
            </ol>
            SalesPrep software provides a secure alternative to internet searches. Internet searches are not limited to the categories above and do not provide privacy.
        </div>
    }
</div>