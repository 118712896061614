<div class="header">
    <div class="flex">
        <app-ghostwriter-avatar />
        <div class="text" [innerHTML]="formattedText"></div>
    </div>
    <app-speaking-animation [class.speaking]="speaking" />
</div>

@if (!reviewMode) {
    
    <div class="main-wrapper flex">
        <app-step-legend [step]="step" />
        
        <div class="input-wrapper">
            
            @if (options?.length) {
                <div class="options">
                    @for (o of options; track o.title) {
                        <div (click)="onSelect.emit(o)" class="option" [class.selected]="o.selected" [innerHTML]="o.title"></div>
                    }
                </div>
            } @else if (options?.length !== 0) {
                <div class="input">
                    <textarea #textAreaElement class="textarea" [(ngModel)]="_input" (ngModelChange)="textChange()"></textarea>
                </div>
            }
        
            @if (options?.length !== 0) {
                <app-voice-input-toggle [listening]="listening"
                                        (click)="onToggleListening.emit()"
                                        [class.options-input]="options?.length"
                                         />    
            }
        </div>
    </div>
    
    <app-form-navigation-buttons (onPrev)="prev()" (onNext)="next()" [nextTitle]="nextTitle" />
}