import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Company} from '../../model';

@Component({
    selector: 'app-company-logo',
    standalone: true,
    imports: [],
    templateUrl: './company-logo.component.html',
    styleUrl: './company-logo.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyLogoComponent {
    @Input() company: Company | undefined;
}
