@if (!inlineMode) {
    <app-top-progress-bar [step]="5" />
    <app-side-progress-bar [step]="5" [company]="company" [offering]="product" [job]="job" />
}
<div class="left-column">
        
    @if (!inlineMode) {
        <app-main-panel>
            <div class="flex mobile-layout">
                <app-report-builder-header [company]="company" [offering]="product" [job]="job"/>
                <!--        @if (reportGenerated()) {-->
                <!--            <a class="open-report" [routerLink]="['/report', reportData()?.id]">Open Report &gt;&gt;&gt;</a>-->
                <!--        }-->
            </div>
            <div class="title" qa="confirm">
                <strong>
                    4. Select Report Topics
                </strong>
            </div>
        </app-main-panel>
    }
    
    <app-main-panel [secondary]="true" [padding]="0" class="sp-form">
        
        @for (topics of discussionTopics; track topics; let index = $index) {
            @if (topics.role) {
                <h3 class="role" [attr.qa]="'roles-' + (index + 1)" qa2="h3-role">Role: {{topics.role}}</h3>
            }
            <app-discussion-topics-list
                    [animated]="!inlineMode" [index]="1" (onSelect)="title.set('Market Trends');selectedTopic.set($event)"
                    [rating]="marketTrendsRating" (onRatingChange)="onRatingChange.emit($event)"
                    title="Market Trends" [loading]="topics.marketTrendsLoading()" [loadingFailed]="topics.marketTrendsLoadingFailed()" [topics]="topics.marketTrends()" (onRetry)="onMarketTrendsRetry.emit()" qa="trends"/>
            <hr>
            <app-discussion-topics-list
                    [animated]="!inlineMode" [index]="2" (onSelect)="title.set('Common Problems');selectedTopic.set($event)"
                    [rating]="commonProblemsRating" (onRatingChange)="onRatingChange.emit($event)"
                    title="Common Problems" [loading]="topics.commonProblemsLoading()" [loadingFailed]="topics.commonProblemsLoadingFailed()" [topics]="topics.commonProblems()" (onRetry)="onCommonProblemsRetry.emit()" qa="problems"/>
            <hr>
            <app-discussion-topics-list
                    [animated]="!inlineMode" [index]="3" (onSelect)="title.set('Process Steps');selectedTopic.set($event)"
                    [rating]="processStepsRating" (onRatingChange)="onRatingChange.emit($event)"
                    title="Process Steps" [loading]="topics.processStepsLoading()" [loadingFailed]="topics.processStepsLoadingFailed()" [topics]="topics.processSteps()" (onRetry)="onProcessStepsRetry.emit()" qa="steps"/>
            <hr>
        }

        @if (stakeholders.length) {
            <app-discussion-topics-list
                    [animated]="!inlineMode" [index]="4"
                    [rating]="stakeholdersRating" (onRatingChange)="onRatingChange.emit($event)"
                    title="Company Stakeholders"
                    [loading]="stakeholdersLoading"
                    [loadingFailed]="stakeholdersLoadingFailed"
                    [topics]="stakeholders"
                    (onRetry)="onStakeholdersRetry.emit()"
                    qa="stakeholders"/>
            <hr>
        }

        <app-discussion-topics-list
                [newsMode]="true"
                [inline]="true"
                [animated]="!inlineMode" [index]="0" (onSelect)="onSelectNewsArticle($event)"
                [rating]="companyNewsRating" (onRatingChange)="onRatingChange.emit($event)"
                title="Company News" [loading]="companyNewsLoading" [loadingFailed]="companyNewsLoadingFailed" [topics]="newsTopics" (onRetry)="onCompanyDataRetry.emit()" qa="news"/>

        @if (!inlineMode) {
            <hr>
            
            <div class="questions" qa="exp-q">
                <div class="title" qa="tit">Exploratory Questions</div>
                
                @for (q of questions; track q; let i = $index) {
                    @if (q.edit) {
                        <div class="flex">
                            <textarea appAutofocus rows="3" [(ngModel)]="q.text" qa="text"></textarea>
                            <a class="sp-link save" (click)="save(q)" qa="done">DONE</a>
                        </div>
                    } @else {
                        <div class="sp-checkbox question" [attr.qa]="'q-' + (i + 1)">
                            <input id="q{{ i }}" [checked]="q.selected" (change)="toggle(q)" type="checkbox" qa="inp">
                            <label for="q{{ i }}">
                                <span qa="lbl">{{ q.text }}</span>&nbsp;
                                <a class="sp-link save" (click)="edit($event, q)" qa="ed">EDIT</a>
                            </label>
                        </div>
                    }
                }

                @if (exploratoryQuestionsLoading) {
                    @if (!questions.length) {
                        <app-spinner/>
                    }
                } @else {
                    <a class="add-new" (click)="add()">
                        <span class="material-symbols-outlined">add_box</span>
                        <span class="text" qa="add">Add question</span>
                    </a>
                }
            </div>
        }
        
        <!--<app-discussion-topics-list 
                [animated]="false" [index]="4" (onSelect)="addOtherTopic = false;selectedCustomDiscussionTopic = $event"
                [rating]="otherTopicsRating" (onRatingChange)="onRatingChange.emit($event)"
                title="Other Topics" [topics]="otherTopics" qa="topics"/>
        
        <a class="add-other-topic" (click)="onAddDiscussionTopic.emit()" qa="add-topic">
            <i class="material-icons">add</i>
            <span class="text" qa="lbl">Add discussion topic</span>
        </a>-->
        
        <div class="actions" qa="actions">            
            @if (inlineMode) {
                <button type="button" (click)="onCancelEditMode.emit()" class="back desktop-layout" qa="back">
                    Back
                </button>
            } @else {
                <button [routerLink]="['/report-builder', 'details']" class="back desktop-layout" qa="back">
                    Back
                </button>
            }
            
            <button class="primary" type="submit" (click)="onNext.emit()" [disabled]="loading" qa="submit">
                @if (inlineMode) { Save } @else { Generate Report }
            </button>
        </div>
    </app-main-panel>
</div>

@if (selectedTopic()) {
    <app-info-panel [title]="title()" (onClose)="selectedTopic.set(undefined)">
        <div class="info-title" [innerHTML]="selectedTopic()?.title"></div>
        <div class="info-text" [innerHTML]="selectedTopic()?.description"></div>
    </app-info-panel>
}

@if (selectedCustomDiscussionTopic) {
    <app-info-panel [title]="'Add Discussion Topic'" (onClose)="cancelAddTopic()">
        <form #customTopicForm="ngForm" (ngSubmit)="saveCustomTopic()" class="sp-form sp-form-alternative custom-topic">
            <label for="topic">Topic</label>
            <textarea appAutofocus required id="topic" name="topic" rows="5" [(ngModel)]="selectedCustomDiscussionTopic.title"></textarea>
            <button class="primary" type="submit" [disabled]="customTopicForm.invalid">
                @if (addOtherTopic) { Add } @else { Save } Topic
            </button>
        </form>
    </app-info-panel>
}
