<svg width="1100" height="30" viewBox="0 0 1100 30"
     xmlns="http://www.w3.org/2000/svg">
    <g fill="white">
        <circle cx="10"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0s"/></circle>
        <circle cx="40"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.1s"/></circle>
        <circle cx="70"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.2s"/></circle>
        <circle cx="100" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.3s"/></circle>
        <circle cx="130" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.4s"/></circle>
        <circle cx="160" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.5s"/></circle>
        <circle cx="190" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.6s"/></circle>
        <circle cx="220" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.7s"/></circle>
        <circle cx="250" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.8s"/></circle>
        <circle cx="280" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.9s"/></circle>
        <circle cx="310"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0s"/></circle>
        <circle cx="340"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.1s"/></circle>
        <circle cx="370"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.2s"/></circle>
        <circle cx="400" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.3s"/></circle>
        <circle cx="430" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.4s"/></circle>
        <circle cx="460" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.5s"/></circle>
        <circle cx="490" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.6s"/></circle>
        <circle cx="520" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.7s"/></circle>
        <circle cx="550" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.8s"/></circle>
        <circle cx="580" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.9s"/></circle>
        <circle cx="610"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0s"/></circle>
        <circle cx="640"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.1s"/></circle>
        <circle cx="670"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.2s"/></circle>
        <circle cx="700" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.3s"/></circle>
        <circle cx="730" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.4s"/></circle>
        <circle cx="760" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.5s"/></circle>
        <circle cx="790" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.6s"/></circle>
        <!-- Added 10 more circles -->
        <circle cx="820"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.7s"/></circle>
        <circle cx="850"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.8s"/></circle>
        <circle cx="880"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.9s"/></circle>
        <circle cx="910"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0s"/></circle>
        <circle cx="940"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.1s"/></circle>
        <circle cx="970"  cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.2s"/></circle>
        <circle cx="1000" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.3s"/></circle>
        <circle cx="1030" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.4s"/></circle>
        <circle cx="1060" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.5s"/></circle>
        <circle cx="1090" cy="20" r="4"><animate attributeName="r" values="2;8;2" dur="0.8s" repeatCount="indefinite" begin="0.6s"/></circle>
    </g>
</svg>
