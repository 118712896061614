import {OfferingActionPipe} from "../../../pipes/offering-action.pipe";
import {MyProblemBriefReportGenerator} from "../problem-brief-report-generator";

export class HeaderSection {
    constructor(private title: string) {
    }

    draw(pdf: MyProblemBriefReportGenerator) {
        const r = pdf.report;
        const text = r.job ? new OfferingActionPipe().transform(r.offeringType, true) : '';
        const y = 20.036 - 5;
        const x = pdf.styles.paddingX;
        
        pdf.forEachPage((doc, index) => {
            if (index == 1) return;
            
            pdf.line(pdf.styles.paddingX, 28.196, 522, "#7abfeb", 1);
            
            doc.setTextColor(0, 0, 0);
            const w = pdf.text(this.title + ' for ' + r.title, x, y, 'Raleway-Bold', 8);
            pdf.text(` | For ${r.offeringName} ${text} ${r.job}`, pdf.styles.paddingX + w, y, 'Raleway-Regular', 8);

            // Sales (blue)
            doc.setTextColor(33, 150, 243);
            pdf.text("Sales", 490.769, y, 'Raleway-Medium', 9);

            // Prep (orange)
            doc.setTextColor(255, 87, 34);
            // const text1Width = doc.getTextWidth("Sales");        
            pdf.text("Prep", 514.583, y, 'Raleway-MediumItalic', 9);

            doc.setTextColor(33, 150, 243);

            // TM (smaller size and raised)        
            pdf.text("®", 533.538, 16.648 - 3, 'Raleway-Medium', 3);

            // REPORT (blue)
            pdf.text("REPORT", 541.286, 20.152 - 3.5, 'Raleway-Medium', 7);
            
        });
    }
}