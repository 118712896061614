import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Company, Offering} from "../../../../model";
import {
    ReportBuilderHeaderComponent
} from "../../../report-builder/components/report-builder-header/report-builder-header.component";

@Component({
    selector: 'app-call-report-steps-modal',
    standalone: true,
    imports: [
        ReportBuilderHeaderComponent
    ],
    templateUrl: './call-report-steps-modal.component.html',
    styleUrl: './call-report-steps-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallReportStepsModalComponent {
    @Input() company: Company | undefined;
    @Input() offering: Offering | undefined;
    @Input() job: string | undefined;
    @Input() customerLocation = '';
    @Input() meetingDate: Date | undefined;

    @Output() onClose = new EventEmitter();
}
