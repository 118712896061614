import {Component, computed, signal} from '@angular/core';
import {PureSelectTopicsComponent} from "./pure-select-topics/pure-select-topics.component";
import {
    PureSelectDiscussionTopicsComponent
} from "../../../report-builder/screens/select-discussion-topics/components/pure-select-discussion-topics/pure-select-discussion-topics.component";
import {DiscussionTopic, MeetingType, ReportSectionRating, ReportSectionType, SalesPrepReport} from "../../../../model";
import {ReportBuilderService} from "../../../../services/report-builder.service";
import {RatingService} from "../../../../services/rating.service";
import {Router} from "@angular/router";
import {InfoPanelComponent} from "../../../../components/info-panel/info-panel.component";
import {RateSectionDialogComponent} from "../../../../components/rate-section-dialog/rate-section-dialog.component";
import {generate} from "rxjs";
import {
    SetMeetingDateArgs
} from "../../../report-builder/screens/set-meeting-date/components/pure-set-meeting-date/pure-set-meeting-date.component";

@Component({
    selector: 'app-select-topics',
    standalone: true,
    imports: [
        PureSelectTopicsComponent,
        InfoPanelComponent,
        RateSectionDialogComponent
    ],
    templateUrl: './select-topics.component.html',
    styleUrl: './select-topics.component.scss'
})
export class SelectTopicsComponent {
    company = this.reportBuilderService.company;
    companyDataLoading = this.reportBuilderService.companyDataLoading;
    companyDataLoadingFailed = this.reportBuilderService.companyDataLoadingFailed;
    
    companyNewsLoading = this.reportBuilderService.companyNewsLoading;
    companyNewsLoadingFailed = this.reportBuilderService.companyNewsLoadingFailed;

    stakeholders = this.reportBuilderService.stakeholders;
    stakeholdersLoading = this.reportBuilderService.stakeholdersLoading;
    stakeholdersLoadingFailed = this.reportBuilderService.stakeholdersLoadingFailed;
    exploratoryQuestions = this.reportBuilderService.exploratoryQuestions;
    exploratoryQuestionsLoading = this.reportBuilderService.exploratoryQuestionsLoading;
    exploratoryQuestionsLoadingFailed = this.reportBuilderService.exploratoryQuestionsLoadingFailed;

    product = this.reportBuilderService.offering;
    job = this.reportBuilderService.job;
    reportData = this.reportBuilderService.reportData;

    discussionTopics = this.reportBuilderService.discussionTopics;
    otherTopics = this.reportBuilderService.otherTopics;

    companyNewsRating!: ReportSectionRating;
    exploratoryQuestionsRating!: ReportSectionRating;
    stakeholdersRating!: ReportSectionRating;
    marketTrendsRating!: ReportSectionRating;
    commonProblemsRating!: ReportSectionRating;
    processStepsRating!: ReportSectionRating;
    otherTopicsRating!: ReportSectionRating;

    selectedCustomDiscussionTopic: DiscussionTopic | undefined;
    badReportSectionRating: ReportSectionRating | undefined;

    saving = signal(false);
    sessionExpired = signal(false);
    
    loading = computed(() => {
        return this.companyDataLoading();
    });

    generatingReport = this.reportBuilderService.generatingReport;
    companyData = this.reportBuilderService.companyData;
    newsArticles = this.reportBuilderService.newsArticles;

    constructor(private reportBuilderService: ReportBuilderService,
                private ratingService: RatingService,
                private router: Router) {
        const o = reportBuilderService.offering();
        if (!o || !o.type || !reportBuilderService.job()) {
            this.router.navigate(['/practice']);
        }

        // reportBuilderService.job.set('_');

        this.companyNewsRating = this._rating(ReportSectionType.CompanyNews);
        this.exploratoryQuestionsRating = this._rating(ReportSectionType.ExploratoryQuestions);
        this.stakeholdersRating = this._rating(ReportSectionType.Stakeholders);
        this.marketTrendsRating = this._rating(ReportSectionType.MarketTrends);
        this.commonProblemsRating = this._rating(ReportSectionType.CommonProblems);
        this.processStepsRating = this._rating(ReportSectionType.ProcessSteps);
        this.otherTopicsRating = this._rating(ReportSectionType.OtherTopics);

        this.ratingService.reset();
    }

    private _rating(section: ReportSectionType): ReportSectionRating {
        return {
            section,
            rating: undefined,
            description: '',
        }
    }

    companyDataRetry() {
        this._retry();
    }

    marketTrendsRetry() {
        this._retry();
    }

    commonProblemsRetry() {
        this._retry();
    }

    processStepsRetry() {
        this._retry();
    }

    private _retry() {
        const job = this.job();
        this.job.set('');
        this.job.set(job);
    }

    saveCustomTopic(topic: DiscussionTopic) {
        const r = this.reportBuilderService;
        if (!r) throw Error('Report is not defined');

        const list = r.otherTopics();
        if (list.indexOf(topic) == -1) {
            r.otherTopics.update(x => [...x, topic]);
        }
    }

    addDiscussionTopic() {
        this.selectedCustomDiscussionTopic = {
            selected: true,
            title: '',
            description: ''
        };
    }
    
    rate(rating: ReportSectionRating, closeDialog = false) {
        console.log('[SelectDiscussionTopicsComponent] rate', rating);
        const companyId = this.company()?.id;
        if (!companyId) return;

        if (rating.rating === false) {
            this.badReportSectionRating = rating;
        }

        this.ratingService.rateSection({
            companyId: +companyId,
            offeringId: this.product()!.id,
            job: this.job() ?? '',
            ...rating
        }).subscribe(() => {
            console.log('[SelectDiscussionTopicsComponent] rate success');
            if (closeDialog) {
                this.badReportSectionRating = undefined;
            }
        })
    }

    save() {
        this.saving.set(true);
        
        this.reportBuilderService.saveReport().subscribe({
            next: (data: SalesPrepReport) => {                
                this.ratingService.saveBuilderRatings(data.id).subscribe({
                    next: () => console.log('[SetMeetingDateComponent] assigned ratings to report'),
                    error: (e: any) => console.error(e),
                    complete: () => this._openReport(data.id)
                });
            },
            error: (e) => {
                if (e.error.error == 'The Access Code is invalid') {
                    this.sessionExpired.set(true);
                }
            },
            complete: () => this.saving.set(false)
        });
    }

    private _openReport(reportId: string) {
        console.log('[SelectTopicsComponent] openReport', reportId);
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        this.router.navigate(['/report', reportId]);
    }
    
    generate(benefits: string[]) {
        console.log('[SelectTopicsComponent] Generate', benefits);
        this.reportBuilderService.generateExploratoryQuestions();
    }
    
}
