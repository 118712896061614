import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SpinnerComponent} from "../../../../../../components/spinner/spinner.component";

@Component({
    selector: 'app-generation-status',
    standalone: true,
    imports: [
    ],
    templateUrl: './generation-status.component.html',
    styleUrl: './generation-status.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerationStatusComponent implements OnChanges {
    @Input() name = '';
    
    @Input() loading = false;
    @Input() failed = false;
    
    @Input() count = 0;
    
    icon = 'progress_activity';
    tooltip = '';
    
    ngOnChanges() {
        if (this.failed) {
            this.icon = 'error';
            this.tooltip = 'failed to load';
        } else {
            this.icon = this.loading ? 'progress_activity' : 'check_circle';
            this.tooltip = '';
        }
    }
}
