<a class="logo" title="Home" [routerLink]="['/']" qa="title-home">
    <div class="title">
        Sales<em>Prep</em><span class="tm">&reg;</span>
    </div>
    <div class="legend">
        Created by The AIM Institute
<!--        <span class="beta">BETA</span>-->
    </div>
</a>

<div class="links" qa="navi">
    @if (authenticated) {
        <a class="desktop-layout" title="Dashboard" routerLinkActive="active" [routerLink]="['/dashboard']" qa="dashboard">Dashboard</a>
        <a class="desktop-layout" routerLinkActive="active" [routerLink]="['/reports']" qa="reports">My Reports</a>
        <a class="desktop-layout" routerLinkActive="active" [routerLink]="['/e-learning']" qa="e-learn">E-Learning</a>
        <a class="desktop-layout" routerLinkActive="active" [routerLink]="['/ai-guide']" qa="ai">AI Guide</a>
        <a class="desktop-layout" routerLinkActive="active" [routerLink]="['/resources']" qa="res">Resources</a>
    } @else {
        <a class="desktop-layout" title="Home page" [routerLink]="['/']" qa="home">Home</a>
        <a class="desktop-layout" routerLinkActive="active" title="Pricing" [routerLink]="['/pricing']" qa="home">Pricing</a>
        <a class="desktop-layout" routerLinkActive="active" title="Contact Us" [routerLink]="['/contact-us']" qa="contact-us">Contact Us</a>
        <a class="desktop-layout" title="Webinars" [routerLink]="['/webinars']" qa="webinars">Webinar</a>
        <a class="desktop-layout signup" title="Sign Up" [routerLink]="['/signup']" qa="signup">Start 30-day trial</a>        
        <a class="desktop-layout login" title="Log in" [routerLink]="['/login']" qa="login">Log in</a>
    }

    <a (click)="toggle($event)" class="menu-icon no-print" [class.mobile-layout]="!authenticated">
        @if (authenticated) {
            <div class="desktop-layout">
                <span qa="prof">
                    {{ userProfile?.firstName }}
                    {{ userProfile?.lastName }}
                </span>
                <span class="material-symbols-outlined expand">@if (open) { expand_less } @else { expand_more }</span>
            </div>
        }
        <span class="material-symbols-outlined mobile-layout" qa="more">
            more_vert            
        </span>
    </a>
    
        <div class="menu" [class.open]="open" appClickOutside (clickOutside)="hide()">
            @if (authenticated) {
                <a class="mobile-layout" title="Home page" routerLinkActive="active" [routerLink]="['/dashboard']">Dashboard</a>
                <a class="mobile-layout" title="My Reports" routerLinkActive="active" [routerLink]="['/reports']" qa="reports">My Reports</a>
                <a class="mobile-layout" title="E-Learning" routerLinkActive="active" [routerLink]="['/e-learning']" qa="menu-learn">E-Learning</a>
                <a class="mobile-layout" title="AI Guide" routerLinkActive="active" [routerLink]="['/ai-guide']" qa="menu-ai">AI Guide</a>
                <a class="mobile-layout" title="Resources"  routerLinkActive="active" [routerLink]="['/resources']" qa="menu-res">Resources</a>
                <a title="View Account" routerLinkActive="active" [routerLink]="['/profile']" qa="my-acc">Account &amp; My Offerings</a>
                <a title="Webinars" href="/webinars">Webinars</a>
                <a title="Help" target="_blank" href="https://insights.theaiminstitute.com/aim-institute-request-support">Help</a>
                <a title="Log Out" (click)="onLogout.emit()" qa="menu-lo">Log Out</a>
            } @else {
                <a class="mobile-layout" title="Home page"  routerLink="/" qa="home">Home</a>
                <a class="mobile-layout" title="Pricing"  routerLink="/pricing" qa="pricing">Pricing</a>
                <a class="mobile-layout" title="Contact Us"  routerLink="/contact-us" qa="contact-us">Contact Us</a>
                <a class="mobile-layout" title="Sign Up"  routerLink="/signup" qa="signup">Start 30-day trial</a>
                <a title="Webinars" href="/webinars">Webinars</a>
                <a class="mobile-layout" title="Log in"  routerLink="/login" qa="login">Log in</a>
            }            
        </div>
    
</div>
