<div class="left">
    
    <h1 class="first">Here’s how it works</h1>
    
    <div class="claire">
        <app-tutor-avatar class="main"/>
        
        <div class="flex-column wrapper">
            <div class="user">
                You will be the interviewer 
                <app-user-avatar class="user" [class.anonymous]="!authenticated" [initials]="userInitials"/>
            </div>
            <div class="customer">
                AI will play your customer
                <app-user-avatar class="customer" [initials]="'C'"/>
            </div>
            <div class="guide">
                <div>
                    Claire will be your AI Guide 
                    @if (!authenticated) {
                        through an<br>intermediate practice session
                    }
                </div>
                <app-tutor-avatar class="coach" />
            </div>
        </div>
    </div>
    
    <p>
        You will use the WHAT-WHY-CLARIFY probing method taught in the
        <a routerLink="{{ authenticated ? '/e-learning' : '/'}}" fragment="elearning">e-learning</a> 
        included with your SalesPrep<sup>&reg;</sup> software subscription. It’s as simple as asking the customer for problems with their application of your type of offering. For each problem you should ask:
    </p>
    
    <div class="quotes">
        <blockquote class="alternative">
            <b>2-3 WHAT questions</b>, such as…
            <ul>
                <li>"Can you describe this problem?"</li>
                <li>"When do you see this problem?"</li>
                <li>"How often does this problem occur?"</li>
            </ul>
        </blockquote>
        
        <blockquote class="alternative">
            <b>1-2 WHY questions</b>, such as…
            <ul>
                <li>"How does this problem impacts you?"</li>
            </ul>
        </blockquote>
        
        <blockquote class="alternative">
            <b>CLARIFY</b> by proposing an outcome statement (that begins with a verb)…
            <ul>
                <li>"So do you want to... <em>minimize time to calibrate the unit?</em>"</li>
                <li>"Would you like to... <em>maximize resistance to corrosion?</em>"</li>
            </ul>
        </blockquote>
    </div>
    
    <p>
        After each question you ask, you’ll get an AI customer response and some coaching advice from Claire. Throughout the interview, you’ll be astonished at how realistic this practice session is. And following our probing method and Claire’s advice will get easier every time you use it and make you more successful at closing real deals!
        <br><br>
    </p>
    
    <app-try-ai-guide-form [authenticated]="authenticated" [offering]="offering" [job]="job" [message]="message" (onSubmit)="onSubmit.emit($event)"/>
</div>

<div class="right">
    <div class="pane">
        <img class="try-ai-guide" src="/assets/images/try-ai-guide/girl.jpg"/>
        <img class="try-ai-guide-2x" src="/assets/images/try-ai-guide/girl@2x.jpg"/>
        
        @if (authenticated) {
            <h2>
                Talk to the AI “customer” just like your real customer. 
                Always focus on WHAT-WHY-CLARIFY. 
                
                <br><br>
                Need a refresher on probing?
                Go through <a href="/api/user/elearning-session/3">e-learning module 3</a>.
            </h2>
        } @else {
            <h2>You’ll be amazed at the realism of the AI “customer” as you discuss your offerings and applications!</h2>
        }
    </div>
</div>