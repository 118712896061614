import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter, HostListener,
    Input,
    Output,
} from '@angular/core';
import {FormsModule, NgForm} from "@angular/forms";
import {AutosizeDirective} from "../../../../directives/autosize.directive";
import {AutofocusDirective} from "../../../../directives/autofocus.directive";
import {SpeechRecognitionService} from "../../../../services/speech-recognition.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {tap} from "rxjs";
import {VoiceInputToggleComponent} from "../../../../components/voice-input-toggle/voice-input-toggle.component";

@Component({
    selector: 'app-message-form',
    standalone: true,
    imports: [
        FormsModule,
        AutosizeDirective,
        AutofocusDirective,
        VoiceInputToggleComponent
    ],
    templateUrl: './message-form.component.html',
    styleUrl: './message-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageFormComponent {
    @Input() message = '';
    @Input() loading = false;
    
    @Output() send = new EventEmitter<string>();

    speechServiceEnabled = this.speechService.enabled;
    recording = this.speechService.recording;
    transcript = this.speechService.transcript;

    constructor(private cdref: ChangeDetectorRef, private speechService: SpeechRecognitionService) {
        toObservable(this.transcript).pipe(
            tap(transcript => this._set(transcript)),
        ).subscribe(() => console.log('transcript...'));
        toObservable(this.speechService.transcriptCompleted).pipe(
            tap(transcript => this._transcriptCompleted(transcript)),
        ).subscribe(() => console.log('transcriptCompleted...'));
    }

    @HostListener('document:keydown.space', ['$event'])
    handleSpace(event: KeyboardEvent) {
        const targetElement = event.target as HTMLElement;
        if (targetElement && targetElement.tagName.toLowerCase() === 'textarea') {
            return;
        }
        
        event.preventDefault();
        this.speechService.toggleRecording();
    }
    
    submit(f: NgForm) {
        if (this.loading || !f.valid) return;

        this._submit();
    }
    
    private _submit() {
        this.speechService.stop();
        this.send.emit(this.message);
        this.message = '';
        this.cdref.detectChanges();
    }

    keydown(e: KeyboardEvent, f: NgForm) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();

            this.submit(f);
        }
    }

    toggleRecording() {
        this.speechService.toggleRecording();        
    }

    private _transcriptCompleted(message: string) {
        if (!!message && this.message == message) {
            console.log('[MessageFormComponent] Recognition Completed', this.message, message);
            this._submit();
        }
    }
    private _set(message: string) {
        if (this.recording()) {
            console.log('[MessageFormComponent] _set', message);
            this.message = message;
            this.cdref.detectChanges();
        }
    }
}
