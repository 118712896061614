import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import {InfoPanelComponent} from "../../../../../../components/info-panel/info-panel.component";
import {MainPanelComponent} from "../../../../../../components/main-panel/main-panel.component";
import {
    ReportBuilderHeaderComponent
} from "../../../../components/report-builder-header/report-builder-header.component";
import {Company, DiscussionTopic, MeetingDetails, MeetingType, Person, RelationshipType} from "../../../../../../model";
import {Offering} from "../../../../../../model/offering";
import {DatePipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {TopProgressBarComponent} from "../../../../components/top-progress-bar/top-progress-bar.component";
import {SideProgressBarComponent} from "../../../../components/side-progress-bar/side-progress-bar.component";
import {RouterLink} from "@angular/router";
import {DatePickerComponent} from "../../../../../../components/date-picker/date-picker.component";
import {TimePickerComponent} from "../../../../../../components/time-picker/time-picker.component";
import {AutofocusDirective} from "../../../../../../directives/autofocus.directive";
import {MeetingTypePipe} from "../../../../../../pipes/meeting-type.pipe";
import {RelationshipTypePipe} from "../../../../../../pipes/relationship-type.pipe";

export interface Purpose {
    text: string;
    selected?: boolean;
}

export enum ReportGenerationTypes {
    Unknown,
    Quick,
    Detailed    
}
@Component({
    selector: 'app-pure-set-meeting-date',
    standalone: true,
    providers: [
        DatePipe
    ],
    imports: [
        InfoPanelComponent,
        MainPanelComponent,
        ReportBuilderHeaderComponent,
        FormsModule,
        DatePipe,
        TopProgressBarComponent,
        SideProgressBarComponent,
        RouterLink,
        DatePickerComponent,
        TimePickerComponent,
        AutofocusDirective,
        MeetingTypePipe,
        RelationshipTypePipe
    ],
    templateUrl: './pure-set-meeting-date.component.html',
    styleUrl: './pure-set-meeting-date.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureSetMeetingDateComponent implements OnInit, OnChanges {

    @Input() customers: Person[] = [];
    @Input() inlineMode = false;
    @Input() company: Company | undefined;
    @Input() product!: Offering;
    @Input() job = '';
    
    @Input() saving = false;

    @Input() meetingDetails!: MeetingDetails;

    // @Input() customerCity = '';
    // @Input() customerState = '';
    // @Input() meetingType = MeetingType.Unknown;
    // @Input() relationshipType = RelationshipType.Unknown;
    
    @Input() reportGenerationType = ReportGenerationTypes.Quick;
    
    // @Input() date = '';
    // @Input() time = '';

    selectedDate = '';
    selectedTime = '';
    
    today = new Date();
    tomorrow = new Date(+new Date() + 1000 * 60 * 60 * 24);
    
    private _today = '';
    private _tomorrow = '';
    
    @Input() showDatePicker = false;
    @Input() showTimePicker = false;
    @Output() onSave = new EventEmitter<SetMeetingDateArgs>();
    @Output() onCancelEditMode = new EventEmitter();

    @HostBinding('class.sp-screen') s = true;
    
    MeetingType = MeetingType;
    RelationshipType = RelationshipType;
    ReportGenerationTypes = ReportGenerationTypes;

    customPurpose = '';
    purposes: Purpose[] = [
        { text: 'Uncover customer problems/opportunities to pursue' },
        { text: 'Demonstrate my company & I can provide value' },
        { text: 'Present our offering\'s benefits/value proposition' },
        { text: 'Get introduction to other stakeholders at this account' }
    ]

    potentialBenefitsTips = [
        'Type potential benefit (e.g. reduce defect rates)',
        'Type potential benefit (e.g. increase production line speed)',
        'Type potential benefit (e.g. reduce training time)'
    ];

    constructor(private datePipe: DatePipe) {
    }
    
    get datePickerValue() : Date | undefined {
        if (!this.selectedDate) {
            return undefined;
        }
        const args = this.meetingDetails.date.split('-');
        if (args.length != 3) {
            return undefined;
        }
        return new Date(+args[0], +args[1] - 1, +args[2]);
    }
    
    set datePickerValue(date: Date | undefined) {
        if (!date) return;
        this.selectedDate = 'custom';
        this.meetingDetails.date = this.datePipe.transform(date, "yyyy-MM-dd")!;
    }
    
    ngOnInit() {
        this._today = this.datePipe.transform(new Date(this.today), "yyyy-MM-dd")!;
        this._tomorrow = this.datePipe.transform(new Date(this.tomorrow), "yyyy-MM-dd")!;
    }
    
    ngOnChanges() {
        if (this.meetingDetails.date == this._today || this.meetingDetails.date == this._tomorrow) {
            this.selectedDate = this.meetingDetails.date;
        } else {
            this.selectedDate = this.meetingDetails.date ? 'custom' : '';
        }
        this.selectedTime = this.meetingDetails.time ? 'custom' : '';
        
        const list = this.meetingDetails.meetingPurpose;
        this.purposes.forEach(x => x.selected = !!list.find(i => i == x.text));
        const customPurposes = list.filter(x => !this.purposes.find(p => p.text == x));
        this.customPurpose = customPurposes.join('. ');
    }

    save(generateReport: boolean) {
        console.log('[SetMeetingDateComponent] save', this.meetingDetails, this.selectedDate, this.selectedTime);

        // const time = this.selectedTime == 'custom' ? this.meetingDetails.time : this.selectedTime;        
        this.meetingDetails.date = this.selectedDate == 'custom' ? this.meetingDetails.date : this.selectedDate;
        
        const purpose = this.purposes.filter(x => x.selected).map(x => x.text);
        if (this.customPurpose) {
            purpose.push(this.customPurpose);
        }
        this.meetingDetails.meetingPurpose = purpose;

        this.onSave.emit({
            generateReport     
        });
    }
    addRole() {
        this.customers.push({name: '', title: ''});
    }

    removeRole(index: number) {
        this.customers.splice(index, 1);
    }

    toggle(purpose: Purpose) {
        purpose.selected = !purpose.selected;
        // this.onChange.emit();
    }
}


export interface SetMeetingDateArgs {
    generateReport: boolean;
}