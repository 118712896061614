<app-pure-generation
        (onOpen)="save()"
        [company]="company()!"
        [product]="product()!"
        [job]="job()!"
        
        [discussionTopics]="discussionTopics()"

        [stakeholders]="stakeholders().length"
        [stakeholdersLoading]="stakeholdersLoading()"
        [stakeholdersLoadingFailed]="stakeholdersLoadingFailed()"
        
        [companyNews]="companyNews().length"
        [companyNewsLoading]="companyNewsLoading()"
        [companyNewsLoadingFailed]="companyNewsLoadingFailed()"

        [meetingOpeners]="meetingOpeners().length"
        [meetingOpenersLoading]="meetingOpenersLoading()"
        [meetingOpenersLoadingFailed]="meetingOpenersLoadingFailed()"
        
        [loadingDiscussionTopics]="loadingDiscussionTopics()"
        
        [openingReport]="openingReport()"
/>