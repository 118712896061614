<app-top-progress-bar [step]="1"/>
<app-side-progress-bar [step]="1"/>

<div class="left-column">
    
    @if (companyData) {
        <app-add-company #addCompanyComponent [(company)]="companyData" (onAdd)="onCompanyNotFound.emit(companyData)" />

        <div class="sp-form">
            <div class="actions desktop-layout">
                <button (click)="companyData = undefined" class="back" qa="back">
                    Back
                </button>
                <button class="primary" type="submit" (click)="onCompanyNotFound.emit(companyData)" [disabled]="!isCompanyDataValid()" qa="next">
                    Next
                </button>
            </div>
        </div>
        
    } @else {
    
        <app-main-panel>
        
            <h1 class="desktop-layout">1. Enter target company name or URL</h1>
            <h1 class="search-tip">
                You can refine your search, e.g., "Amazon in Paris" or "adhesive producers in Chicago."
            </h1>
            <label class="mobile-layout">New report for</label>
            <app-new-report [compact]="true" [query]="searchQuery" (queryChange)="search($event)" (onSearch)="search($event)"></app-new-report>
        
            <div class="note mobile-layout" qa="sel1">
                1. Select who you’re preparing for. (If you can’t find it, enter the company URL instead.)
            </div>
        
        </app-main-panel>    
        
        @if (searchResultsLoading) {
            <app-spinner></app-spinner>
        } @else {
            <div class="items">
                @for (company of companies; track company.name; let i = $index) {
                    <a (click)="onSelectCompany.emit(company)" class="mobile-layout">
                        <app-company-list-item [company]="company" [attr.qa]="'comp-' + (i + 1)"></app-company-list-item> 
                    </a>
                    
                    <div class="item desktop-layout">
                        <div class="sp-selectable" [class.selected]="i == selectedIndex">
                            <input type="radio" id="offering-{{i}}" value="{{i}}" [(ngModel)]="selectedIndex" qa="inp">
                            <label for="offering-{{i}}" qa="lbl">
                                <app-company-list-item [company]="company"></app-company-list-item>
                            </label>                    
                        </div>
                    </div>
                }
    
                @if (!searchQueryChanged && searchQuery && searchQuery.length > 1) {
                    <app-company-not-found [companyNotFound]="companyNotFound" (onNext)="addCompany(searchQuery)"/>
                }
            </div>
    
            <div class="sp-form">
                <div class="actions desktop-layout">
                    <button [routerLink]="['/dashboard']" class="back" qa="back">
                        Back
                    </button>
                    <button class="primary" type="submit" (click)="onSelectCompany.emit(companies[selectedIndex])" [disabled]="selectedIndex == -1" qa="next">
                        Next
                    </button>
                </div>
            </div>
    
            
        }
    }

</div>