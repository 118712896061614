

<div class="container twelve-ways-{{ currentStep }}">
    <app-main-header [sticky]="false" />
    
    <app-page-header class="desktop-layout" [title]="'12 Ways to Use SalesPrep'"/>
    
    <div class="twelve-ways">
        <div class="sp-wrapped">
            <app-twelve-way-slider ngSkipHydration [step]="currentStep"/>
            
            <div class="note"><a (click)="scrollToGrid()">Scroll</a> to view these applications of SalesPrep<sup>&reg;</sup> software in a comparison grid.</div>
        </div>
    </div>
</div>

<div #grid class="grid">
    <div class="sp-wrapped">
        <app-twelve-way-comparition-grid/>
    </div>
</div>

<app-footer-smart [noMargin]="true" />


