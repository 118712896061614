import {CallReportEditData, MeetingType} from "../../model";
import {CallReportVoiceInputSteps} from "./call-report-voice-input-steps";
import {CallReportVoiceInputStep, CallReportVoiceInputStepWithOptions} from "./model";
import {CustomerLocationPipe} from "../../pipes/customer-location.pipe";

export class StartCallReportStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.Start,
            'Let’s start with a meeting overview. I’ll ask you for details I don’t already have.\nJust say “skip” or “next” if you don’t have an answer.',
            [
                { title: '<em>Begin Interview</em>', transcript: 'start', selected: true },
                { title: 'Go <em>back to text mode</em>', transcript: 'back-to-text' },
            ]
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[1].selected) {
            return 0;
        }
        
        return this.step + 1;
    }

    override getValue = (data: CallReportEditData) => {
        return this.options[0].transcript;
    };
}
export class CustomerLocationStep extends CallReportVoiceInputStep {
    _pipe = new CustomerLocationPipe();
    
    constructor() {
        super(
            CallReportVoiceInputSteps.CustomerLocation,
            'First, what was the customer’s location?\nPlease say both city and state or country.'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (!transcript) return this.step;
        data.customerCity = transcript;
        data.customerState = '';
        return this.step + 1;
    }
    
    override getValue = (data: CallReportEditData) => 
        this._pipe.transform(data);
}

export class MeetingTypeStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.MeetingType,
            'Was this meeting in-person or virtual?',
            [
                { title: '<em>In-person</em>', transcript: 'in person' },
                { title: '<em>Virtual</em>', transcript: 'virtual' },
            ]
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[0].selected) {
            data.meetingType = MeetingType.InPerson;            
        } else if (this.options[1].selected) {
            data.meetingType = MeetingType.Virtual;            
        } else {
            return this.step;
        }
        return CallReportVoiceInputSteps.MeetingPurpose;
    }

    override getValue = (data: CallReportEditData) => {
        if (data.meetingType == MeetingType.InPerson) return this.options[0].transcript;
        if (data.meetingType == MeetingType.Virtual) return this.options[1].transcript;
        return '';
    };
}

export class ReviewMeetingOverviewQuestionStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.ReviewMeetingOverviewQuestion,
            'That’s it for the meeting overview. Would you like to review all your answers or move onto the next step?',
            [
                { title: '<em>Review</em>.', transcript: 'review' },
                { title: '<em>Move</em> onto step 2.', transcript: 'move' },
            ]
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[0].selected) {
            return CallReportVoiceInputSteps.ReviewMeetingOverview;
        } else if (this.options[1].selected) {
            return data.outcomes.length == 0
                ? CallReportVoiceInputSteps.OutcomesQuestion
                : CallReportVoiceInputSteps.OutcomeWhy;
        }
        return this.step;
    }
}

export class ReviewMeetingOverviewStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.ReviewMeetingOverview,
            'Here are all the answers I’ve collected for the meeting overview. Tap any of the answers to fix them. When you’re finished reviewing, just click next.'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        return data.outcomes.length == 0
            ? CallReportVoiceInputSteps.OutcomesQuestion
            : CallReportVoiceInputSteps.OutcomeWhy;
    }
}

export class InsightsStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.Insights,
            'Any other insights?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        data.insights = transcript;
        return this.step + 1;
    }

    override getValue = (data: CallReportEditData) => data.insights;
}

export class OpportunitiesStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.Opportunities,
            'Any opportunities to pursue?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        data.opportunities = transcript;
        return this.step + 1;
    }

    override getValue = (data: CallReportEditData) => data.opportunities;
}

export class NextStepsStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.NextSteps,
            'What are the next steps?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        data.nextSteps = transcript;
        return this.step + 1;
    }

    override getValue = (data: CallReportEditData) => data.nextSteps;
}

export class NotesStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.Notes,
            'Anything else we should include?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        data.notes = transcript;
        return this.step;
    }

    override getValue = (data: CallReportEditData) => data.notes;
}
