import {Pipe, PipeTransform} from '@angular/core';
import {RelationshipType} from "../model";

@Pipe({
    name: 'relationshipType',
    standalone: true
})
export class RelationshipTypePipe implements PipeTransform {

    transform(value: RelationshipType, ...args: unknown[]): string {
        switch (value) {
            case RelationshipType.FirstMeeting:
                return 'First meeting with this person(s)';
            case RelationshipType.ExistingRelationship:
                return 'Existing relationship';
            default:
                return '';
        }
    }

}
