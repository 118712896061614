import {PdfGenerator} from "../pdf-generator";
import {NotesSection} from "./notes-section";
import {CompanyNewsArticleSection} from "./company-news-article-section";
import {IReportPart} from "../ireport-part";
import {max} from "rxjs";
import {StakeholderSection} from "./stakeholder-section";


export class CompanyNewsSection implements IReportPart {
    
    constructor(private pdf: PdfGenerator) {
    }
    
    draw(startOffsetY: number) {
        // let startOffsetY = 245.924;
        
        const pdf = this.pdf;
        const meetingOpeners = pdf.report.meetingOpeners;
        const questions = pdf.report.exploratoryQuestions.filter(x => x.selected);
        const stakeholders = pdf.report.stakeholders.filter(x => x.selected);
        const newsArticles = pdf.report.newsArticles.filter(x => x.selected);
        if (!newsArticles.length) {
            return 0;
        }

        const notesMarginTop = 11;
        const notesMarginBottom = 30;
        const stakeholdersMarginTop = 10;
        const newsMarginTop = 30;
         
        let offsetY = startOffsetY;

        if (meetingOpeners.length > 0) {
            pdf.partTitle(offsetY, 'MEETING OPENERS', 'questions', 18, 17.765);
            offsetY += 24.889;
            const x = pdf.styles.paddingX;
            const maxWidth = 339.947;
            offsetY = pdf.text('Ask the following questions to uncover problems you can probe with What-Why-Clarify.', x, offsetY,
                'Raleway-MediumItalic', 9, maxWidth) + 6.929;
            meetingOpeners.forEach((q, i) => {
                offsetY = pdf.text(`${i + 1}.  ${q.question}`, x, offsetY, 'Raleway-Bold', 10, maxWidth) + 3;
                offsetY = pdf.text(`${q.rationale}`, x, offsetY, 'Raleway-MediumItalic', 9, maxWidth) + 6.929;
            });
            offsetY += 31.775 - 6.929;
        }
        
        if (questions.length > 0) {
            pdf.partTitle(offsetY, 'EXPLORATORY QUESTIONS', 'questions', 18, 17.765);
            offsetY += 24.889;
            const x = pdf.styles.paddingX;
            const maxWidth = 339.947;
            offsetY = pdf.text('Ask the following questions to uncover problems you can probe with What-Why-Clarify.', x, offsetY,
                'Raleway-MediumItalic', 9, maxWidth) + 6.929;
            questions.forEach((q, i) => {
                offsetY = pdf.text(`${i + 1}.  ${q.text}`, x, offsetY, 'Raleway-Bold', 10, maxWidth) + 6.929;
            });
            offsetY += 31.775 - 6.929;
        }

        if (stakeholders.length > 0) {
            pdf.partTitle(offsetY, 'COMPANY STAKEHOLDERS', 'leadership', 14.8, 16.4);
            offsetY += 20;

            stakeholders.forEach((x, i) => {
                offsetY += stakeholdersMarginTop;
                const s = new StakeholderSection(pdf, x, i + 1);
                if (offsetY + s.getHeight() > pdf.pageHeight) {
                    // offsetY = pdf.addPage()
                    new NotesSection().draw(pdf, 405.945, startOffsetY + notesMarginTop, offsetY - notesMarginBottom);

                    pdf.doc.addPage();
                    offsetY = pdf.partSubTitle('COMPANY STAKEHOLDERS CONTINUED...');
                    startOffsetY = offsetY;
                    // new NotesSection().draw(pdf, 405.945, 70);
                }
                offsetY = s.draw(offsetY);
            });
        }        
        
        pdf.partTitle(offsetY + 20, 'COMPANY NEWS', 'company-news', 18, 12);
        offsetY += 20;
        
        newsArticles.forEach(a => {
            offsetY += newsMarginTop;
            const s = new CompanyNewsArticleSection(pdf, a);
            if (offsetY + s.getHeight() > pdf.pageHeight) {
                // offsetY = pdf.addPage()
                new NotesSection().draw(pdf, 405.945, startOffsetY + notesMarginTop, offsetY - notesMarginBottom);
                
                pdf.doc.addPage();
                offsetY = pdf.partSubTitle('COMPANY NEWS CONTINUED...');
                startOffsetY = offsetY;
                // new NotesSection().draw(pdf, 405.945, 70);
            }
            offsetY = s.draw(offsetY);
        });

        new NotesSection().draw(pdf, 405.945, startOffsetY + notesMarginTop, offsetY - notesMarginBottom);
        return offsetY + newsMarginTop;
    }
}