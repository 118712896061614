<app-main-header/>

<app-page-header 
    [title]="'Free 30-min SalesPrep<sup>&reg;</sup> webinar'"
    [text]="'Plus 30-min additional Q&A after the presentation'">

    <svg xmlns="http://www.w3.org/2000/svg" width="102.999" height="70.999" viewBox="0 0 102.999 70.999">
        <g id="Live_Webinar_Icon" data-name="Live Webinar Icon" transform="translate(-48 -84)">
            <path id="Subtraction_73" data-name="Subtraction 73" d="M176-11802H106a2,2,0,0,1-2-2v-7h52a2,2,0,0,0,2-2v-43h18a2,2,0,0,1,2,2v50A2,2,0,0,1,176-11802Zm-20-11H104v-41a2,2,0,0,1,2-2h50v43Z" transform="translate(-27 11957)" fill="#fff"/>
            <g id="Subtraction_74" data-name="Subtraction 74" transform="translate(-27 11957)" fill="none">
                <path d="M86-11811a2,2,0,0,1-2-2v-50a2,2,0,0,1,2-2h70a2,2,0,0,1,2,2v9H106v43H86Z" stroke="none"/>
                <path d="M 85.99978637695312 -11813 L 85.99950408935547 -11813 L 104.0009689331055 -11813.0009765625 L 104.0009689331055 -11856.0009765625 L 156.0012054443359 -11856.0009765625 L 156.0021514892578 -11862.9970703125 C 156.00146484375 -11862.9990234375 155.9995422363281 -11863.0009765625 155.9999084472656 -11863.0009765625 L 86.00211334228516 -11863.001953125 C 86.00091552734375 -11863.001953125 85.9990234375 -11863 85.99861145019531 -11863 C 85.99859619140625 -11863 85.99858856201172 -11863 85.99858093261719 -11863 L 85.99735260009766 -11813.00390625 C 85.998046875 -11813.001953125 85.99996948242188 -11813 85.99978637695312 -11813 M 106.0009689331055 -11811 L 85.99960327148438 -11811 C 84.89623260498047 -11811 83.99858093261719 -11811.8974609375 83.99858093261719 -11813.0009765625 L 83.99858093261719 -11863 C 83.99858093261719 -11864.103515625 84.89623260498047 -11865.0009765625 85.99960327148438 -11865.0009765625 L 155.9999084472656 -11865.0009765625 C 157.103271484375 -11865.0009765625 158.0009307861328 -11864.103515625 158.0009307861328 -11863 L 158.0009307861328 -11854.0009765625 L 106.0009689331055 -11854.0009765625 L 106.0009689331055 -11811 Z" stroke="none" fill="#fff"/>
            </g>
            <circle id="Ellipse_317" data-name="Ellipse 317" cx="16.5" cy="16.5" r="16.5" transform="translate(48 84)" fill="#f9a11a"/>
            <path id="Polygon_85" data-name="Polygon 85" d="M9.4,0l9.4,15.223H0Z" transform="matrix(0.848, -0.53, 0.53, 0.848, 50.768, 96.855)" fill="#fff"/>
            <text id="Live" transform="translate(83 126)" fill="#379fe0" font-size="20" font-family="Raleway-Bold, Raleway" font-weight="700"><tspan x="0" y="0">LIVE</tspan></text>
            <text id="Webinar" transform="translate(84 136)" fill="#379fe0" font-size="9" font-family="Raleway-Bold, Raleway" font-weight="700"><tspan x="0" y="0">WEBINAR</tspan></text>
        </g>
    </svg>

</app-page-header>

<div class="sp-wrapped content">
    <div [innerHtml]="safeHtml"></div>
</div>

<app-footer-smart />