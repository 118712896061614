import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MeetingOpener, ReportSectionRating} from "../../../../model";
import {RateSectionComponent} from "../../../../components/rate-section/rate-section.component";
import {RateSectionFooterComponent} from "../../../../components/rate-section-footer/rate-section-footer.component";

@Component({
    selector: 'app-meeting-openers',
    standalone: true,
    imports: [
        RateSectionComponent,
        RateSectionFooterComponent
    ],
    templateUrl: './meeting-openers.component.html',
    styleUrl: './meeting-openers.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeetingOpenersComponent {
    @Input() meetingOpeners: MeetingOpener[] = [];
    @Input() rating!: ReportSectionRating;
    @Output() onRatingChange = new EventEmitter<ReportSectionRating>();

}
