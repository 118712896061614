<h3>AI GUIDE</h3>

<h1>Meet Clair, your AI Guide</h1>

<div class="content">

    <div class="left">
        
        <div class="avatar" title="Meet Claire, your AI Guide in the SalesPrep&reg; system">            
        </div>
        
        <p>
            Have you seen anyone master new skills without practicing? Neither have we. Now you can role-play with an AI
            Customer — specific to your product application — and get advice from Claire, your AI Guide. Just enter one
            of your products and your customer’s application. Then ask Claire to guide you at one of three practice
            levels:
        </p>

        <app-select-coaching-level [coachingLevel]="coachingLevel" (onSelect)="coachingLevel = $event" />

        <p>
            You really must try this! You’ll be amazed by the AI Customer’s answers that offer real problems in your
            product’s application area. And Claire will give great advice on how you can improve your probing questions.
        </p>

    </div>

    <div class="right">
        <h2 class="center">
            Practice interviews before your sales meeting
            and get guidance that will help you get better results!
        </h2>

        <div class="flex">
            <div class="items step-{{step}}" title="Practice customer probing with the SalesPrep&reg; AI Guide">
                <button (click)="prev()" [disabled]="!step">
                    <span class="material-symbols-outlined">arrow_back_ios</span>
                </button>
                <div class="item questions">
                    <h1>Ask probing questions</h1>
                    
                    <div class="message m1">
                        Before I tell you about our products,
                        can you tell me what challenges you're facing today?
                    </div>
                    <div class="message m2">
                        How much of a lag time are you seeing?
                    </div>
                    <div class="message m3">
                        Can you tell me what's causing this lag time?
                    </div>
                    <div class="message m4">
                        So how does this impact you?
                    </div>
                </div>
    
                <div class="item answers">
                    <h1>Get realistic customer answers</h1>
                    
                    <div class="message">
                        Certainly. We've noticed a couple of challenges with our current system.
                        Firstly, the data collection isn't as real-time or accurate as we need it
                        to be for swift decision-making. There's a lag in data reporting that
                        hampers our ability to respond quickly to air quality deteriorations.
                        Secondly, our current equipment struggles with durability, especially 
                        in more extreme weather conditions, which leads to frequent maintenance 
                        and data gaps.
                    </div>
                </div>
    
                <div class="item advices">
                    <h1>View Claire's coaching advice</h1>
                    
                    <div class="message">
                        <app-tutor-avatar/>
                        You asked an insightful "What" question that uncovers a specific
                        problem the customer is facing.                    
                    </div>
                    <div class="message">
                        <app-tutor-avatar class="right"/>
                        Next, explore deeper with a "Why" question to understand the implications of these challenges.
                    </div>
                    <div class="message">
                        <app-tutor-avatar/>
                        For example, you could ask, "Why do you think the seals are failing prematurely?" or 
                        "How does this variability in weather conditions affect the performance of the hydraulic cylinders? 
                    </div>
                </div>
                <button (click)="next()" [disabled]="step == 2">
                    <span class="material-symbols-outlined">arrow_forward_ios</span>
                </button>
            </div>
        </div>
        
        <button class="primary" [routerLink]="[ '/ai-guide/intro' ]" qa="try-ai">
            Try a role-play practice session now!
        </button>
    </div>
</div>
