import {CustomerHandoutsGenerator} from "../customer-handouts-generator";
import {UserProfile} from "../../../model";
import {OfferingActionPipe} from "../../../pipes/offering-action.pipe";
import {IReportPart} from "../../pdf-export/ireport-part";

export interface MainHeaderSectionArgs {
    title: string,
    color: string,
    icon: number;
    iconX: number;
    iconY: number;
    iconWidth: number;
    iconHeight: number;
} 
export class MainHeaderSection implements IReportPart {

    private maxWidth = 503;
    private marginBottom = 16.548;
    constructor(
        private pdf: CustomerHandoutsGenerator,
        private args: MainHeaderSectionArgs,
        private company: string,
        private user: UserProfile | undefined) {
    }

    draw(y: number) {
        const pdf = this.pdf;
        const doc = this.pdf.doc;
        const args = this.args;

        const x = 63.620;
        
        pdf.doc.addImage(`assets/customer-handout/${args.icon}.png`, 'png', args.iconX, args.iconY, args.iconWidth, args.iconHeight);

        const topBarHeight = 27.184;
        doc.setFillColor(args.color);
        doc.rect(0, 0, pdf.pageWidth / 2, topBarHeight, 'F');
        doc.setFillColor(pdf.styles.linkColor);
        doc.rect(pdf.pageWidth / 2, 0, pdf.pageWidth / 2, topBarHeight, 'F');

        // Sales (blue)
        doc.setTextColor(33, 150, 243);
        pdf.text("Sales", 454.855, 43.291 - 5, 'Raleway-Medium', 14);

        // Prep (orange)
        doc.setTextColor(255, 87, 34);
        // const text1Width = doc.getTextWidth("Sales");        
        pdf.text("Prep", 490.226, 43.291 - 5, 'Raleway-MediumItalic', 14);

        doc.setTextColor(33, 150, 243);

        // TM (smaller size and raised)        
        pdf.text("®", 517.773, 38.307, 'Raleway-Medium', 5);

        // REPORT (blue)
        pdf.text("REPORT", 529.172, 43.462 - 3, 'Raleway-Medium', 10);


        doc.setTextColor(0, 0, 0);

        
        pdf.text(args.title, x, y, 'Raleway-ExtraBold', 21);

        const r = pdf.report;
        const text = r.job ? new OfferingActionPipe().transform(r.offeringType, true) : '';

        y = pdf.text(`for ${r.offeringName} ${text} ${r.job}`, x, 80.799, 'Raleway-Medium', 16, this.maxWidth);
        

        const u = this.user;
        
        if (u) {
            y = pdf.text(`Prepared for ${this.company} by ${u.firstName} ${u.lastName}, ${u.company} `, x, y, 'Raleway-MediumItalic', 10, this.maxWidth);
        } else {
            y = pdf.text(`Prepared for ${this.company} `, x, y, 'Raleway-MediumItalic', 10, this.maxWidth);    
        }        

        return y + this.marginBottom;
    }
}