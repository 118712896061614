<app-main-header class="sp-desktop"/>

<app-page-header
        [title]="'My Reports'"
        [text]="'Your SalesPrep<sup>&reg;</sup> reports help you prepare for customer meetings in seconds.'" qa="head">

    <svg xmlns="http://www.w3.org/2000/svg" width="43.93" height="63.137" viewBox="0 0 43.93 63.137">
        <g id="Reports_Icon" data-name="Reports Icon" transform="translate(2760 -2485)">
            <path id="Union_85" data-name="Union 85" d="M5.525,61.5V58.847H7.963v4.29ZM3.087,58.847H5.525V61.5L3.087,63.137ZM0,58.847V0H35.319A8.61,8.61,0,0,1,43.93,8.609V50.237a8.611,8.611,0,0,1-8.611,8.609Zm4.593-4.593H35.319a4.021,4.021,0,0,0,4.019-4.017V8.609A4.023,4.023,0,0,0,35.319,4.59H4.593Z" transform="translate(-2760 2485)" fill="#fff"/>
            <rect id="Rectangle_400" data-name="Rectangle 400" width="20.592" height="4" rx="1" transform="translate(-2748.332 2517.048)" fill="#fff"/>
            <rect id="Rectangle_401" data-name="Rectangle 401" width="20.592" height="4" rx="1" transform="translate(-2748.332 2524.318)" fill="#fff"/>
            <rect id="Rectangle_402" data-name="Rectangle 402" width="20.592" height="4" rx="1" transform="translate(-2748.332 2531.587)" fill="#fff"/>
            <path id="Rectangle_710" data-name="Rectangle 710" d="M4.592,4.592v7.817H23.409V4.592H4.592M4.3,0H23.7A4.3,4.3,0,0,1,28,4.3V12.7A4.3,4.3,0,0,1,23.7,17H4.3A4.3,4.3,0,0,1,0,12.7V4.3A4.3,4.3,0,0,1,4.3,0Z" transform="translate(-2752.036 2494.028)" fill="#fff"/>
        </g>
    </svg>
</app-page-header>

@if (reportsLoading()) {
    <app-spinner/>
} @else {
    <app-reports-list class="sp-wrapped sp-padding" [reports]="reports()" (onRemove)="removeReport($event)" qa="rep"/>
}

<app-footer-compact/>