import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CompanyLogoComponent} from "../../../../components/company-logo/company-logo.component";
import {Company, Offering} from "../../../../model";
import {CallReportStepsModalComponent} from "../call-report-steps-modal/call-report-steps-modal.component";

@Component({
    selector: 'app-call-report-steps-modal-button',
    standalone: true,
    imports: [
        CompanyLogoComponent,
        CallReportStepsModalComponent
    ],
    templateUrl: './call-report-steps-modal-button.component.html',
    styleUrl: './call-report-steps-modal-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallReportStepsModalButtonComponent {
    @Input() company: Company | undefined;
    @Input() offering: Offering | undefined;
    @Input() job: string | undefined;

    @Input() customerLocation = '';
    @Input() meetingDate: Date | undefined;
    
    @Input() showByDefault = false;

    showModal = false;
}
