<div class="left">

    <h1>Create a SalesPrep<sup>&reg;</sup> report for one of <em>your</em> customers… using <em>your</em> offering.</h1>

    <p qa="desc">
        First, enter your <b class="offering">Offering</b><span class="offering"> (what you’re selling)</span> and the customer’s
        <b class="job-to-be-done">Application</b><span class="job-to-be-done"> (job-to-be-done with what you’re selling)</span>. For example…
    </p>

    <div class="examples flex" qa="ex">
        <div>
            <h2 class="offering">My Offering examples</h2>
            <p qa="ex-1">Thickener</p>
            <p qa="ex-2">Corrugated boxes</p>
            <p qa="ex-3">Hydraulic cylinders</p>
            <p qa="ex-4">Bottling Equipment</p>
        </div>

        <div class="separator">
            <h2>&nbsp;</h2>
            <p>(Used to) =></p>
            <p>(Used to) =></p>
            <p>(Used to) =></p>
            <p>(Used to) =></p>
        </div>
        <div>
            <h2 class="job-to-be-done">Application examples</h2>
            <p qa="jt-1">Produce paint</p>
            <p qa="jt-2">Ship merchandise</p>
            <p qa="jt-3">Manufacture backhoes</p>
            <p qa="jt-4">Produce soft drinks</p>
        </div>
    </div>

    <form #form="ngForm" (ngSubmit)="onSubmit.emit(data)" class="sp-form">

        <p qa="cust-lbl">Customer website URL or name:</p>

        <div class="company-search">
            <input appAutofocus [autofocus]="true" autocomplete="off" placeholder="Enter the company website URL"
                   (focus)="showDropDown = true" (blur)="showDropDown = false"
                   name="company" [(ngModel)]="companyQuery" (ngModelChange)="companyQueryChange.emit(companyQuery)" required qa="search">

            @if (showDropDown && (searchResultsLoading || companies.length)) {
                <div class="dropdown">
                    @if (searchResultsLoading) {
                        <app-spinner />
                    } @else if (companies.length) {
                        @for (company of companies; track company.name; let i = $index) {
                            <a (mousedown)="select(company)">
                                <app-company-list-item [arrowVisible]="false" [company]="company" [attr.qa]="'comp-' + (i + 1)"></app-company-list-item>
                            </a>
                        }
                    }
                </div>
            }
        </div>

        <input name="companyName" type="hidden" [ngModel]="company?.name" required>

        <p class="offering" qa="off-lbl">My offering is described as (1-3 words):</p>
        <input name="offering" type="text" [(ngModel)]="data.offering" placeholder="Enter your offering" required qa="offering">

        <p class="job-to-be-done" qa="job-lbl">The customer will use it to (start with a verb):</p>
        <input name="job" type="text" [(ngModel)]="data.jobToBeDone" placeholder="Enter the customer application" required qa="job">

        <p qa="code-lbl">Access code (provided at practice session):</p>
        <input name="code" minlength="3" type="text" [(ngModel)]="data.accessCode" placeholder="Enter the customer application" required qa="code">

        @if (errorMessage) {
            <p class="warn" qa="warn">{{ errorMessage }}</p>
        }

        <button type="submit" class="primary" [disabled]="form.invalid" qa="submit">
            Create report
        </button>
    </form>
</div>

<div class="right">
    <div class="pane">
        <img class="try-ai-guide" src="/assets/images/try-ai-guide/girl.jpg"/>
        <img class="try-ai-guide-2x" src="/assets/images/try-ai-guide/girl@2x.jpg"/>

        <h2>Create in-depth, pre-visit customer reports in 60 seconds!</h2>
    </div>
</div>