
@if (offering) {
    <form #offeringForm="ngForm" (ngSubmit)="save(offeringForm)">
        <div class="sp-form">

            @if (category) {
                <app-offering-type [type]="category.type" [headerMode]="true" [text]="category.name" qa="cat-name"></app-offering-type>
            }
            
            <label for="name" qa="l1">3. My offering is described as (1-3 words):</label>
            <input appAutofocus [autofocus]="!offering.name" required type="text" name="name" id="name" [(ngModel)]="offering!.name" qa="name" maxlength="255">

            @switch (offering.type) {
                @case (OfferingType.MaterialOrIngredientOrComponent) {
                    <label qa="l2">4. Customers use it to produce (use nouns)</label>
                }
                @case (OfferingType.EquipmentOrToolOrConsumable) {
                    <label qa="l2">4. Customers use it to (start with verbs)</label>                    
                }
                @default {
                    <label qa="l2">4. Optional: If your offering can be used for different applications, list them (start with verbs):</label>
                }
            }
            
            <div class="jobs">
                @for (job of offering!.jobs; track offering; let i = $index) {
                    <div class="job">
                        <input  appAutofocus [autofocus]="!job" required name="job-{{i}}" type="text" [ngModel]="job" (ngModelChange)="offering!.jobs[i] = $event" (keydown)="onKeyUp($event, $event.code)" [attr.qa]="'job-' + (i + 1)">
                        @if (offering.type == OfferingType.ServiceOrSoftware || offering!.jobs.length > 1) {
                            <a (click)="removeJob(offering, i)" qa="delete-job"><i class="material-icons remove">close</i></a>
                        }
                    </div>
                }
            </div>
            
            <button type="button" class="alternative" (click)="offering!.jobs.push('')" qa="add-job">
                <i class="material-icons">add</i>
                Add @if (offering!.jobs.length > 0) { another } job-to-be-done
            </button>
            
            <div class="buttons">
                <button class="primary" type="submit" [disabled]="offeringForm.invalid" qa="submit">
                    @if (offering.id > 0) {
                        Save
                    } @else {
                        Add This
                    }
                    Offering            
                </button>
                
                @if (offering.id > 0) {
                    <button type="button" (click)="onRemove.emit(offering)" class="delete" qa="delete">Delete Offering</button>
                }
            </div>
        </div>
    </form>
}