<a class="close" (click)="onClose.emit()" qa="mw-cl"><i class="material-icons">close</i></a>

<app-report-builder-header [company]="company" [offering]="offering" [job]="job"
                           [customerLocation]="customerLocation"
                           [meetingDate]="meetingDate" qa="mw-he"/>
<ul class="steps" qa="mw-st">
    <li><strong qa="mw-st-t">Create call report steps:</strong></li>
    <li qa="mw-st-1">1. Meeting Overview</li>
    <li qa="mw-st-2">2. Add Outcomes</li>
    <li qa="mw-st-3">3. Other Things Learned</li>
    <li qa="mw-st-4">4. Review &amp; Edit</li>
</ul>