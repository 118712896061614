import {Injectable, signal} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {Title} from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    
    confirmTitle = signal('');
    confirmMessage = signal('');
    
    private _confirmAction: (() => void) | undefined;

    constructor(private toastr: ToastrService) {}

    success(message: string, title: string | undefined = undefined) {
        console.log('[MessageService] success', message, title);
        this.toastr.success(message, title, {
            positionClass: 'toast-top-center'
        });
    }

    info(message: string, title: string | undefined = undefined) {
        console.log('[MessageService] info', message, title);
        this.toastr.info(message, title, {
            positionClass: 'toast-top-center'
        });
    }

    error(message: string, title: string | undefined = undefined) {
        console.log('[MessageService] error', message, title);
        this.toastr.error(message ,title, {
            positionClass: 'toast-top-center',
            enableHtml: true,
            disableTimeOut: true
        });
    }

    warning(message: string, title: string | undefined = undefined, disableTimeOut = true) {
        console.log('[MessageService] warning', message, title);
        this.toastr.warning(message ,title, {
            positionClass: 'toast-top-center',
            enableHtml: true,
            disableTimeOut
        });
    }

    confirm(message: string, action: () => void, title: string = 'Are you sure you want to proceed?') {
        this.confirmTitle.set(title);        
        this.confirmMessage.set(message);
        this._confirmAction = action;
    }
    
    onConfirm() {
        if (!!this._confirmAction) {
            this._confirmAction();                       
        }
        this._reset();
    }
    
    onCancel() {
        this._reset();
    }

    private _reset() {
        this.confirmMessage.set('');
        this._confirmAction = undefined;
    }

    notImplemented() {
        this.info('Not implemented yet');
    }

    cancelConfirmation() {
        this._reset();
    }
}
