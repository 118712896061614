import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';
import {DiscussionTopic, ReportSectionRating} from "../../../../../../model";
import {DiscussionTopicComponent} from "../discussion-topic/discussion-topic.component";
import {RetryComponent} from "../retry/retry.component";
import {
    RateSectionFooterComponent
} from "../../../../../../components/rate-section-footer/rate-section-footer.component";

@Component({
    selector: 'app-discussion-topics-list',
    standalone: true,
    imports: [
        DiscussionTopicComponent,
        RetryComponent,
        RateSectionFooterComponent
    ],
    templateUrl: './discussion-topics-list.component.html',
    styleUrl: './discussion-topics-list.component.scss',
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscussionTopicsListComponent implements OnInit, OnChanges {

    @Input() rating!: ReportSectionRating;
    @Input() index = 1;
    @Input() animated = true;
    @Input() animatedForStorybook = false;
    @Input() loading = false;
    @Input() title = '';
    @Input() loadingFailed = false;
    @Input() inline = false;
    @Input() newsMode = false;
    @Input() topics: DiscussionTopic[] = [];

    @Output() ratingChange = new EventEmitter<boolean>();
    @Output() onRetry = new EventEmitter();
    @Output() onSelect = new EventEmitter<DiscussionTopic>();
    @Output() onRatingChange = new EventEmitter<ReportSectionRating>();

    @ViewChildren(DiscussionTopicComponent) items!: QueryList<DiscussionTopicComponent>;

    allSelected = true;
    indeterminate = false;

    emptyTopic = {
        selected: false,
        title: '_',
        description: ''
    }
    
    
    ngOnInit() {
        if (this.animatedForStorybook) {
            this.addRandom();
        }
    }
    
    ngOnChanges(changes: SimpleChanges) {
        this.init();  
    }

    init() {
        const l = this.topics;
        const selected = !!l.find(x => x.selected);
        const notSelected = !!l.find(x => !x.selected);

        this.allSelected = l.length == 0 || (selected && !notSelected);
        this.indeterminate = selected && notSelected;
    }

    toggleSelection() {
        console.log('[DiscussionTopicsListComponent] toggleSelection');
        if (this.indeterminate) {
            this.indeterminate = false;
        }
        if (this.items) {
            this.items.forEach(x => x.topic!.selected = !this.allSelected);
            this.allSelected = !this.allSelected;
        }
    }
    
    addRandom() {
        if (this.topics.length > 20) return;
        this.topics.push(this.topics[Math.random() * this.topics.length | 0]);
        setTimeout(() => this.addRandom(), 1000);
    }
}
