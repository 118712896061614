@if (inline) {
    <div class="header">WAYS TO USE SALESPREP</div>
}

<div class="container">
    <a (click)="prev()" class="nav prev"><span class="material-symbols-outlined">arrow_back_ios</span></a>

    <div class="steps tablet">
        @for (item of steps; track item.title; let i = $index; let even = $even) {
            <a (click)="select(i + 1)">
                <div class="num" [class.selected]="step == (i + 1)">{{ i + 1}}</div>
            </a>
        }
    </div>
    
    <div class="steps">
    @for (item of steps; track item.title; let i = $index; let even = $even) {
        <div class="step indent{{item.indent || 'N'}}" [class.selected]="step == (i + 1)" [class.even]="even" [attr.qa]="'st-' + (i + 1)">
            <a (click)="select(i + 1)">
                @if (inline) {
                    <div class="num">{{ i + 1}}</div>
                }
                <div>
                    <span class="title number"><span qa="num">{{ i + 1 }}</span>.&nbsp;</span>
                </div>
            </a>
            
            <div>
                <a class="title" (click)="select(i + 1)" qa="tit">{{ item.title }}</a>
                <div class="desc" [innerHTML]="item.description" qa="desc"></div>
            </div>
        </div>
    }
    </div>
    
    <div class="info-container" qa="info">
        <div class="info">        
            <div [@fadeOutIn]="step" class="img step-{{ step }}" qa="i-im"></div>
            <div [@fadeOutIn]="step" class="note step-{{ step }}" [innerHTML]="stepsDetails[step-1].note" qa="i-no"></div>
    
            <div class="details" qa="i-d">
                <div class="report">
                    @for (x of stepsDetails[step-1].report; track i; let i = $index) {
                        <div [class.checked]="x">
                            @if (x) { <app-checkmark /> }
                            <span>{{ reportTitle[i] }}</span>
                        </div>
                    }
                </div>
        
                <div class="role-play">
                    @for (x of stepsDetails[step-1].ai; track i; let i = $index) {
                        <div [class.checked]="x">
                            @if (x) { <app-checkmark [ai]="true" /> }
                            <span>{{ rolePlayTitle[i] }}</span>
                        </div>
                    }
                </div>
            </div>
            
            <div class="legend">
                <div class="report">SalesPrep<sup>&reg;</sup> REPORT</div>
                <div class="ai">AI ROLE-PLAY</div>
            </div>
        </div>
    </div>

    <a class="nav next" (click)="next()"><span class="material-symbols-outlined">arrow_forward_ios</span></a>
</div>