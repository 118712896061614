import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CallReportVoiceInputSteps} from "../../../../services/call-report-voice-input";

@Component({
    selector: 'app-step-legend',
    standalone: true,
    imports: [],
    templateUrl: './step-legend.component.html',
    styleUrl: './step-legend.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepLegendComponent {
    @Input() step = CallReportVoiceInputSteps.OutcomeWhy;

}
